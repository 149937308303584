import React, { Component, useEffect, useState } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";
import { useNavigate } from "react-router-dom";

import Metadata from "../../components/Metadata";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import search_icon from "../../assets/images/search_icon.svg";
import action_dots_icon from "../../assets/images/action_dots_icon.svg";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, Spin } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import EtablishmentsModal from "../../components/modals/EtablishmentsModal";

const Etablishments = () => {
  const [establishments, setEstablishments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredEstablishments, setFilteredEstablishments] = useState([]);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [isOpenCreateModal, setOpenCreateModal] = useState(false);

  const getEstablishments = async () => {
    setLoading(true);
    setEstablishments([]);
    await getDocs(
      query(collection(db, "clients"), orderBy("enterprise", "asc"))
    )
      .then((clients) => {
        clients.forEach((doc) => {
          setEstablishments((prev) => [
            ...prev,
            { ...doc.data(), uid: doc.id },
          ]);
        });
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => setLoading(false));
  };

  const deleteClient = async (uid) => {
    setLoadingDelete(true);
    await deleteDoc(doc(db, "clients", uid))
      .then(async () => {
        toast.success("Etablishment deleted");
        await getEstablishments();
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => setLoadingDelete(false));
  };

  const filterEstablishment = (search) => {
    setFilteredEstablishments(
      establishments.filter((client) =>
        client.enterprise.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  useEffect(() => {
    getEstablishments();
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />
      <EtablishmentsModal
        isModalOpen={isOpenCreateModal}
        setOpenModal={() => setOpenCreateModal(!isOpenCreateModal)}
        refresh={() => getEstablishments()}
      />
      <ToastContainer />
      <div className="grid grid--dashboard">
        <Menu />
        <div className="container container--dashboard">
          <div className="container dashboard-header">
            <h2 style={{ width: "50%" }} className="dashboard-title">
              Etablishments
            </h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                block
                icon={<PlusOutlined />}
                style={{
                  background: "#FF4E4E",
                  color: "white",
                  width: "200px",
                  height: "100%",
                }}
                onClick={() => setOpenCreateModal(!isOpenCreateModal)}
              >
                Établissement
              </Button>
            </div>
          </div>

          <div className="archive-toolbar flex-double">
            <div className="searchbar-box">
              <input
                type="text"
                placeholder="Search by name"
                onChange={(e) => filterEstablishment(e.target.value)}
              />
              <img
                src={search_icon}
                alt="Search Bar Icon"
                title="Search Bar Icon"
                className="search-icon"
              />
            </div>
            {/* <div className="tools-one">
              <div className="tools-grp">
                <img
                  src={filter_icon}
                  alt="Filter Icon"
                  title="Filter Icon"
                  className="filter-icon"
                />
              </div>
            </div> */}
          </div>

          <div className="history-entries __header">
            <div className="history-entry history-entry-establishments">
              <div className="history-code"></div>
              <div className="profile">Nom</div>
              <div>Facturation Adresse</div>
              <div>Region</div>
              <div>Date créée</div>
              <div></div>
            </div>
          </div>

          {isLoading ? (
            <div
              style={{
                marginTop: "10rem",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                height: "10rem",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div className="history-list">
              <ul className="history-entries">
                {(filteredEstablishments.length > 0
                  ? filteredEstablishments
                  : establishments
                ).map((establishment, index) => {
                  return (
                    <ClientItem
                      establishment={establishment}
                      key={index}
                      deleteClient={deleteClient}
                      isLoadingDelete={isLoadingDelete}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function ClientItem({ establishment, deleteClient, isLoadingDelete }) {
  const [isMenu, setIsMenu] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = async () => {
    await deleteClient();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <li className="history-entry history-entry-establishments">
      <Modal
        title="Supprimer"
        open={isModalOpen}
        onOk={() => deleteClient(establishment.uid)}
        onCancel={handleCancel}
        okText="Supprimer"
        cancelText="Cancel"
      >
        <p>Êtes-vous sûr de vouloir supprimer cet établissement ?</p>
        {isLoadingDelete && (
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "5rem",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Modal>
      <div className="history-code">
        {establishment.enterprise.substring(0, 1)}
      </div>
      <div className="profile">
        <div className="avatar">
          {establishment.profile_picture_url ? (
            <img
              src={establishment.profile_picture_url}
              alt="Avatar"
              title="Avatar"
            />
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <HomeOutlined />
            </div>
          )}
        </div>
        {establishment.enterprise}
      </div>
      <div className="items" style={{ paddingRight: 20 }}>
        {establishment.facturationAddress?.addressLine1?.address
          ? establishment.facturationAddress?.addressLine1.address
          : "No address"}
      </div>
      <div className="items">
        {establishment.zone === "Sélectionner zone" || !establishment.zone
          ? "No region"
          : establishment.zone}
      </div>
      <div className="items">
        {moment(establishment.createdAt?.toDate()).format("DD/MM/YYYY")}
      </div>

      <button className="items dotsButton" onClick={() => setIsMenu(!isMenu)}>
        {isMenu && (
          <div
            style={{
              position: "absolute",
              background: "white",
              marginBottom: 110,
              borderColor: "gray",
            }}
          >
            <Button
              size="large"
              block
              style={{ background: "rgb(83, 190, 206)", color: "white" }}
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/admin/etablishment-profile/${establishment.uid}`, {
                  state: { establishment: establishment },
                });
                setIsMenu(!isMenu);
              }}
            >
              View Profile
            </Button>
            <Button
              size="large"
              block
              icon={<DeleteOutlined />}
              style={{ background: "rgb(220, 124, 124)", color: "white" }}
              onClick={() => {
                setIsMenu(!isMenu);
                setIsModalOpen(true);
              }}
            >
              Delete etablishment
            </Button>
          </div>
        )}
        <img src={action_dots_icon} alt="Action" title="Action" />
      </button>
    </li>
  );
}

export default Etablishments;
