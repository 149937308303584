// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDqTS5Zw_wz9rsjpdoJzQGgWMjzZaFintM",
  authDomain: "medik-dom.firebaseapp.com",
  databaseURL: "https://medik-dom.firebaseio.com",
  projectId: "medik-dom",
  storageBucket: "medik-dom.appspot.com",
  messagingSenderId: "1031688628805",
  appId: "1:1031688628805:web:3a4a114adfb204a8bbd12c",
  measurementId: "G-VXTSX3STN3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const functions = getFunctions(app);

export { db, auth, functions };
