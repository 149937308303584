import React, { useState } from "react";
import { Link } from "react-router-dom";
import Wheel from "../../assets/images/wheel.svg";
import Blood from "../../assets/images/sante-icon.svg";
import client_avatar from "../../assets/images/client_avatar.png";
import avatar_2 from "../../assets/images/avatar_2.png";
import moment from "moment";
import { UserDeleteOutlined, UserOutlined } from "@ant-design/icons";

const MedicalCard = ({
  urgence,
  driver,
  ticketNo,
  client,
  type,
  reception,
  deposer,
  createdAt,
  receptionDate,
  depotDate,
  transport,
  data,
}) => {
  const [isMenu, setIsMenu] = useState(false);

  const colorTypeValue = () => {
    switch (urgence) {
      case "Relative":
        return "yellow";
      case "Urgence Vitale":
        return "purple";
      default:
        break;
    }
  };

  const state1 = moment(createdAt?.toDate()).add(15, "m").format("HH:mm A");
  const state2 = moment(createdAt?.toDate()).add(30, "m").format("HH:mm A");
  const id = data.uid;
  const idDriver = driver?.id;

  return (
    <div className="card card--medical">
      <div className="card--medical__wrapper">
        <h3 className={`card--medical__title ${colorTypeValue()}`}>
          {type} - {urgence}
        </h3>

        <div className="card--medical__bd sub-head">
          <div className="grid grid--gap2">
            <div className="card--medical__logo">
              {transport === 1 ? (
                <div style={{ display: "flex" }}>
                  <img src={Blood} alt="sante-icon" title="Sante Icon" />

                  <div style={{ fontSize: 15, width: "10%", paddingLeft: 5 }}>
                    Transport Sanguin
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <img src={Wheel} alt="wheelIcon" title="Wheel Icon" />

                  <div style={{ fontSize: 15, width: "10%", paddingLeft: 5 }}>
                    Autre Transport
                  </div>
                </div>
              )}
            </div>
            <div className="card--medical__profile profile">
              <div>
                <strong className="card--medical__text-tip card--medical__text-tip__big">
                  {driver?.name}
                </strong>
                <span className="card--medical__text-tip card--medical__text-tip__fade">
                  {driver?.id}
                </span>
              </div>
              <div className="avatar avatar--2x">
                <UserOutlined />
              </div>
            </div>
          </div>
        </div>

        <div className="card--medical__bd">
          <div className="flex-double">
            <div className="flex-double__l">
              <div className="profile">
                <div className="avatar avatar--3x">
                  <img
                    src={client_avatar}
                    alt="Medical Trnsport"
                    title="Medical Transport"
                  />
                </div>
                <div>
                  <span className="card--medical__text-tip card--medical__text-tip__big">
                    {client?.name}
                  </span>
                  <span className="card--medical__text-tip card--medical__text-tip__fade">
                    {client ? client.addresses[0]?.addressLine1.address : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-double__r __r">
              <div className="card--medical__tls">
                <div
                  className="ticket-btn ticket-btn-number"
                  onClick={() => setIsMenu(!isMenu)}
                >
                  Ticket Nº {ticketNo}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card--medical__bd">
          <div className="flex-double">
            <div className="flex-double__l">
              <div>
                <span className="card--medical__text-tip">
                  Livraison estimée
                </span>
                <span className="card--medical__text-tip card--medical__text-tip__fade">
                  {moment(createdAt?.toDate()).format("DD/MM/YYYY")} |{" "}
                  {depotDate
                    ? moment(depotDate.toDate()).format("HH:mm A")
                    : receptionDate
                    ? moment(receptionDate.toDate())
                        .add(15, "m")
                        .format("HH:mm A")
                    : moment(createdAt?.toDate())
                        .add(30, "m")
                        .format("HH:mm A")}
                </span>
              </div>
            </div>
            <Link
              to={"/admin/tracking"}
              state={{ id, idDriver, data, state1, state2 }}
              className="flex-double__r __r"
            >
              <div className="card--medical__tls">
                <div className="ticket-btn ticket-btn-status">Tracking</div>
              </div>
            </Link>
          </div>
        </div>

        <div className="card--medical__bd">
          <div className="flex-double">
            <div className="flex-double__l">
              <div>
                <span className="card--medical__text-tip card--medical__text-tip__big">
                  Itinéraire
                </span>
                <span className="card--medical__text-tip card--medical__text-tip__big-w">
                  {reception?.location.address}
                </span>
                <span className="card--medical__text-tip card--medical__text-tip__fade">
                  Arrival time |{" "}
                  {receptionDate
                    ? moment(receptionDate.toDate()).format("HH:mm A")
                    : moment(createdAt?.toDate())
                        .add(15, "m")
                        .format("HH:mm A")}
                </span>
              </div>
            </div>

            <div className="flex-double__r __r">
              <div>
                <span className="card--medical__text-tip card--medical__text-tip__big-w">
                  {deposer?.location.address}
                </span>
                <span className="card--medical__text-tip card--medical__text-tip__fade">
                  Arrival time |{" "}
                  {depotDate
                    ? moment(depotDate.toDate()).format("HH:mm A")
                    : receptionDate
                    ? moment(receptionDate.toDate())
                        .add(15, "m")
                        .format("HH:mm A")
                    : moment(createdAt?.toDate())
                        .add(30, "m")
                        .format("HH:mm A")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="card--medical__bd">
          <div className="progress-bar">
            <div className="progress-bar__container">
              <div className="progress-bar__icon completed"></div>

              <div className="progress-bar__progress ">
                <div
                  className={
                    depotDate
                      ? "progress-bar__progress__inner100"
                      : receptionDate
                      ? "progress-bar__progress__inner"
                      : ""
                  }
                ></div>
              </div>

              <div
                className={depotDate ? "progress-bar__icon completed" : ""}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {isMenu && (
        <div className="card--medical__menu">
          <ul>
            <li className="card--medical__menu__option" key="1">
              Create
            </li>
            <li className="card--medical__menu__option" key="2">
              Ticket
            </li>
            <li className="card--medical__menu__option" key="3">
              Product
            </li>
            <li className="card--medical__menu__option" key="4">
              Driver
            </li>
            <li className="card--medical__menu__option" key="5">
              Client
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MedicalCard;
