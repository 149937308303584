import React, { Component } from "react";
import Metadata from "../../components/Metadata";

import Slider from "react-slick";
import Footer from "../../components/Footer";

import { Link } from "react-scroll";
import btnicon from "../../assets/images/btn_icon.svg";
import "../../assets/styles/styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Calendar from "../../assets/images/calendar.svg";
import Phone from "../../assets/images/phone-call.svg";
import logo from "../../assets/images/mdkd_logo.svg";
import transport from "../../assets/images/transport-medical.svg";
import transportCar from "../../assets/images/transport-car.svg";
import ambulance from "../../assets/images/ambulance.svg";
import badge from "../../assets/images/badge.svg";
import route from "../../assets/images/route.svg";
import clock from "../../assets/images/clock.svg";
import urgent from "../../assets/images/urgent.svg";
import KAPA from "../../assets/images/KAPA.svg";
import SETCARGO from "../../assets/images/SETCARGO.svg";
import BIO from "../../assets/images/BIO_POLE_ANTILLES.svg";
import CHUG from "../../assets/images/CHUG.svg";
import Choisy from "../../assets/images/Clinique_de_Choisy.svg";
import CMS from "../../assets/images/CMS+.svg";
import CPA from "../../assets/images/CPA.svg";
import EFS from "../../assets/images/EFS.svg";
import medik from "../../assets/images/fotos_medik_dom.svg";
import { NavLink } from "react-router-dom";
import MessageBox from "../../components/dashboard/MessageBox";

class Inicio extends Component {
  constructor() {
    super();
    this.state = { isNavMenu: false };
    this.toggleNavigationMenu = this.toggleNavigationMenu.bind(this);
  }

  toggleNavigationMenu = () => {
    this.setState({ isNavMenu: !this.state.isNavMenu });
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
    };
    return (
      <div>
        <Metadata />
        <header className="header" id="home">
          <div className="header__top">
            <div className="container container--main">

              <div className="header_first">
                  <div className="margin_header">
                    <img
                      src={Phone}
                      alt="Call Medikdom"
                      title="Call Medikdom"
                      className="__icon"
                    />

                    <strong>TÉL : +590 690 920 727 </strong>
                  </div>
                  <div>
                    <img
                      src={Calendar}
                      alt="Calendar Medikdom"
                      title="Calendar Medikdom"
                      className="__icon"
                    />
                    <strong>TÉL : +590 690 920 727 </strong>
                  </div>
              </div>

              <div className="header_second">
                <NavLink to="/login" className="btn ">
                  <div className="button button--wIcon">
                    <div>
                      <img src={btnicon} title="" alt="" />
                    </div>
                    <strong > 
                      Admin
                    </strong>
                   
                  </div>
                </NavLink>
              </div>

            </div>
          </div>
          

          {/* listo de aca pa arrriba */}

          <div className="header__banner">
            <div className="container container--main header__banner__nav">
              <div className="__logo">
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo of Medikdom"
                    title="Logo of Medikdom"
                    className="__icon"
                  />
                </Link>
              </div>

              <nav className="__nav-mobile">
                <div className="__button" onClick={this.toggleNavigationMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div
                  className={`__nav ${this.state.isNavMenu ? "active" : ""}`}
                >
                  <li className="active">
                    <a href="#home">Accueil</a>
                  </li>
                  <li>
                    <a href="#about">À propos</a>
                  </li>
                  <li>
                    <a href="#services">Prestations de service</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </div>
              </nav>
              <div>
                <ul className="__nav-desktop">
                  <li className="active">
                    <Link
                      to="home"
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={1000}
                    >
                      Accueil
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={45}
                      duration={1000}
                    >
                      À propos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={100}
                      duration={1000}
                    >
                      Prestations de service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="contact"
                      spy={true}
                      smooth={true}
                      offset={45}
                      duration={1000}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
                
              </div>
              
            </div>

            <div className="container container--main">
              <div className="header__banner__hero">
                <h2 className="header__banner__hero__title">
                  Spécialiste du transport Médical en Guadeloupe
                </h2>
                <div className="header__banner__hero__buttons">
                  <a href="#about" className="button button--gr">
                    Notre Entreprise
                  </a>
                  <a href="#contact" className="button button--gr">
                    Travailler avec nous
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* esta parte de arriba no funciona  */}

          <div className="header-slider">
            <Slider {...settings}>
              <div className="slider__item fs"></div>

              <div className="slider__item sc"></div>

              <div className="slider__item th"></div>
            </Slider>
          </div>

          {/* funciona */}
        </header>

        <div
          className="body-section"
          id="about"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in"
          data-sal-duration="400"
        >
          <div className="container container--main">
            <h3 className="body-section__titleM">Qui sommes-nous ?</h3>
            <h3 className="body-section__titleG">
              Spécialiste du Transport Médical en Guadeloupe
            </h3>

            <p className="body-section__desc body-section__desc--min">
              Vestibulum sollicitudin nunc in eros a justo facilisis rutrum.
              Aenean id ullamcorper libero. Vestibulum imperdiet nibh tortor
              mauris
            </p>
          </div>
        </div>

        <div
          id="services"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-in"
          data-sal-duration="400"
        >
          <div className="container container--main">
            <div className="section__eq-sec section__eq-sec--emboite">
              <div className="__section">
                <div className="section__eq-sec__img">
                  <img
                    src={transport}
                    alt="Calendar Medikdom"
                    title="Calendar Medikdom"
                    className="__icon"
                  />
                </div>

                <h3 className="body-section__titleG body-section__titleG--m">
                  Transport médical
                </h3>

                <div className="body-section__desc">
                  <p>
                    Vestibulum sollicitudin nunc in eros a justo facilisis
                    rutrum. Aenean id ullamcorper libero. Vestibulum imperdiet
                    nibh tortor mauris
                  </p>

                  <div>
                    <ul className="list">
                      <li className="list__item">Produits sanguins labiles</li>
                      <li className="list__item">Produits pharmaceutiques</li>
                      <li className="list__item">Echantillons de diagnostic</li>
                      <li className="list__item">Greffes</li>
                      <li className="list__item">Matériel Médical</li>
                      <li className="list__item">Documents Importants</li>
                      <li className="list__item">Plis et colis</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="__section">
                <div className="section__eq-sec__img">
                  <img
                    src={transportCar}
                    alt="Medikdom transport car"
                    title="Medikdom transport car"
                    className="__icon"
                  />
                </div>

                <h3 className="body-section__titleG body-section__titleG--m">
                  Le transport notre métier
                </h3>

                <div className="body-section__desc">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam.
                  </p>

                  <p>
                    Quis aute iure reprehenderit in voluptate velit esse cillum
                    dolore eu fugiat nulla pariatur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* funciona */}

        <div className="body-section body-section--gray">
          <div className="container container--main">
            <h3 className="body-section__titleG">
              Pourquoi travailler avec nous ?
            </h3>

            <p className="body-section__desc body-section__desc--min">
              Vestibulum sollicitudin nunc in eros a justo facilisis rutrum.
              Aenean id ullamcorper libero. Vestibulum imperdiet nibh tortor
              mauris
            </p>

            <div className="work-card-section">
              <div
                className="work-card"
                data-sal="zoom-in"
                data-sal-delay="300"
                data-sal-easing="ease-in"
                data-sal-duration="400"
              >
                <div className="work-card__img">
                  <img
                    src={urgent}
                    alt="Medikdom urgent car"
                    title="Medikdom urgent car"
                  />
                </div>

                <div className="work-card__left">
                  <strong className="work-card__title">24h/24 - 7j/7</strong>
                  <p className="work-card__desc">
                    Nous répondons 24h/24 et 7j/7 à toutes les demandes de
                    transports.
                  </p>
                </div>
              </div>

              <div
                className="work-card"
                data-sal="zoom-in"
                data-sal-delay="400"
                data-sal-easing="ease-in"
                data-sal-duration="400"
              >
                <div className="work-card__img">
                  <img
                    src={clock}
                    alt="Medikdom rapid service"
                    title="Medikdom rapid service"
                  />
                </div>

                <div className="work-card__left">
                  <strong className="work-card__title">Rapidité</strong>
                  <p className="work-card__desc">
                    Un enlèvement et une livraison rapide dans le respect des
                    procédures.
                  </p>
                </div>
              </div>

              <div
                className="work-card"
                data-sal="zoom-in"
                data-sal-delay="500"
                data-sal-easing="ease-in"
                data-sal-duration="400"
              >
                <div className="work-card__img">
                  <img src={route} alt="" title="" />
                </div>

                <div className="work-card__left">
                  <strong className="work-card__title">Suivi</strong>
                  <p className="work-card__desc">
                    Grâce à notre application vous recevez l'information en
                    direct.
                  </p>
                </div>
              </div>

              <div
                className="work-card"
                data-sal="zoom-in"
                data-sal-delay="600"
                data-sal-easing="ease-in"
                data-sal-duration="400"
              >
                <div className="work-card__img">
                  <img src={badge} alt="" title="" />
                </div>

                <div className="work-card__left">
                  <strong className="work-card__title">
                    Personnel Qualifé et professionnel
                  </strong>
                  <p className="work-card__desc">
                    Notre personnel est qualifié et former au métier de messager
                    du sang
                  </p>
                </div>
              </div>

              <div
                className="work-card"
                data-sal="zoom-in"
                data-sal-delay="700"
                data-sal-easing="ease-in"
                data-sal-duration="400"
              >
                <div className="work-card__desc">
                  <p>
                    Vestibulum sollicitudin nunc in eros a justo facilisis
                    rutrum. Aenean id ullamcorper libero. Vestibulum imperdiet
                    nibh tortor mauris.
                  </p>
                </div>
              </div>

              <div
                className="work-card"
                data-sal="zoom-in"
                data-sal-delay="800"
                data-sal-easing="ease-in"
                data-sal-duration="400"
              >
                <div className="work-card__img">
                  <img
                    src={ambulance}
                    alt="Medikdom Ambulance"
                    title="Medikdom Ambulance"
                  />
                </div>

                <div className="work-card__left">
                  <strong className="work-card__title">
                    Equipement adapté
                  </strong>
                  <p className="work-card__desc">
                    Des véhicules adaptés au transport médical, tracés et
                    frigorifiés.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* funciona */}

        <div className="body-section body-section--rd">
          <div className="container container--main">
            <h3 className="body-section__titleG">Plus de 5 ans de service</h3>

            <p className="body-section__desc body-section__desc--min">
              Sollicitudin nunc in eros a justo facilisis rutrum. Aenean id
              ullamcorper libero. Vestibulum imperdiet nibh tortor mauris
            </p>
          </div>
        </div>

        <div className="body-section">
          <div className="container container--main">
            <h3 className="body-section__titleG">ils nous font confiance</h3>

            <p className="body-section__desc body-section__desc--min">
              Looking cautiously round, to ascertain that they were not
              overheard, the two hags cowered nearer to the fire, and chuckled
              heartily.
            </p>

            <div
              className="section__eq-sec"
              data-sal="fade"
              data-sal-delay="700"
              data-sal-easing="ease-in"
              data-sal-duration="400"
            >
              <img src={KAPA} alt="KAPA logo" title="KAPA logo" />

              <img src={SETCARGO} alt="SETCARGO logo" title="SETCARGO logo" />

              <img
                src={BIO}
                alt="BIO POLE ANTILLES logo"
                title="BIO POLE ANTILLES logo"
              />

              <img src={CHUG} alt="CHUG logo" title="CHUG logo" />

              <img
                src={Choisy}
                alt="Clinique_de_Choisy logo"
                title="Clinique_de_Choisy logo"
              />

              <img src={CMS} alt="CMS+ logo" title="CMS+ logo" />

              <img src={CPA} alt="CPA logo" title="CPA logo" />

              <img src={EFS} alt="EFS logo" title="EFS logo" />

              <img
                src={medik}
                alt="fotos medik dom logo"
                title="fotos medik dom logo"
              />
            </div>
          </div>
        </div>
        {/* listo  */}

        <Footer />
      </div>
    );
  }
}

export default Inicio;
