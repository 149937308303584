import React, { useReducer } from "react";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";

const UserState = ({ children }) => {
  const initialState = {
    user: { displayName: "" },
    isLogged: false,
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const updateUser = (user) => {
    dispatch({
      type: "UPDATE_USER",
      payload: user,
    });
    dispatch({
      type: "SET_ISLOGGED",
      payload: true,
    });
    console.log("UPDATE_USER");
  };

  const logOutUser = () => {
    localStorage.removeItem("userMedik");
    dispatch({
      type: "LOGOUT_USER",
      payload: initialState.user,
    });
    dispatch({
      type: "SET_ISLOGGED",
      payload: false,
    });
    console.log("logout");
  };

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        isLogged: state.isLogged,
        updateUser,
        logOutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserState;
