import React, { useEffect, useState } from "react";

import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import folder_2_icon from "../../assets/images/folder_2_icon.svg";
import Profile from "../../assets/svg/profile.svg";
import Tools from "../../assets/svg/tools.svg";
import Time from "../../assets/svg/time.svg";
import { Button, Spin } from "antd";
import {
  sendPasswordResetEmail,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { toast, ToastContainer } from "react-toastify";

const Settings = () => {
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAdmin, setLoadingAdmin] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [changeEmail, setChangeEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState("");

  const getAdmin = async () => {
    setLoadingAdmin(true);
    try {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setAdmin(user);
        } else {
          setAdmin(null);
        }
      });
    } catch (e) {
      console.error(e);
    }
    setLoadingAdmin(false);
  };

  const updateUser = async (userId, newEmail) => {
    const userRef = doc(db, "admins", userId);

    try {
      await updateDoc(userRef, {
        email: newEmail,
      });
      console.log("Document successfully updated!");
      toast.success("Email updated");
      getAdmin();
    } catch (e) {
      console.log("Error updating document:", e);
    }
  };

  const resetEmail = async (admin, newEmail, password) => {
    setLoading2(true);
    var credential = EmailAuthProvider.credential(admin.email, password);
    reauthenticateWithCredential(admin, credential)
      .then(() => {
        updateEmail(admin, newEmail)
          .then(() => {
            updateUser(admin.uid, newEmail);
          })
          .catch((error) => {
            if (
              error.message === "Firebase: Error (auth/email-already-in-use)."
            ) {
              return toast.error("Error: Email already exist.");
            } else {
              toast("Server error");
              console.log(error.message);
            }
          });
      })
      .catch((error) => {
        if (
          error.message === "Firebase: Error (auth/wrong-password)." ||
          error.message === "Firebase: Error (auth/user-not-found)."
        ) {
          return toast.error("Error: wrong credentials.");
        } else {
          toast("Server error");
          console.log(error.message);
        }
      })

      .finally(() => {
        setLoading2(false);
      });
  };

  const resetPassword = async (email) => {
    setLoading(true);
    await sendPasswordResetEmail(auth, email)
      .then(async () => {
        toast.success("E-mail envoyé");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Server error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />
      <ToastContainer />

      <div className="grid grid--dashboard">
        <Menu />
        <div className="container container--dashboard">
          <div className="dashboard-title" style={{ marginBottom: 30 }}>
            Configuration
          </div>
          <div className="flex-double no-center">
            <div className="flex-double__l w-35">
              <div className="archive-hierarchy">
                <div
                  className="archive-hierarchy__item pointer"
                  onClick={() => this.showForm("default")}
                >
                  <div className="label-group">
                    <img src={Profile} alt="Folder icon" title="Folder icon" />
                    Security
                  </div>
                </div>

                {/* <div
                    className="archive-hierarchy__item pointer"
                    onClick={() => this.showForm("notifications")}
                  >
                    <div className="label-group">
                      <img src={Tools} alt="Folder icon" title="Folder icon" />
                      Notifications
                    </div>
                  </div>

                  <div
                    className="archive-hierarchy__item pointer"
                    onClick={() => this.showForm("chat")}
                  >
                    <div className="label-group">
                      <img src={Time} alt="Folder icon" title="Folder icon" />
                      Chat
                    </div>
                  </div> */}
              </div>
            </div>

            <div className="flex-double__r w-65">
              <form className="form form-settings">
                {isLoadingAdmin ? (
                  <div
                    style={{
                      marginTop: "2rem",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      height: "5rem",
                    }}
                  >
                    <Spin size="large" />
                  </div>
                ) : (
                  <>
                    <div className="form-settings__section">
                      <div className="form-title">Change email</div>
                      <div
                        className="input-section"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label className="form-label">Current email</label>
                        <input
                          type="text"
                          disabled={true}
                          placeholder={`${admin?.email}`}
                          className="form-input"
                        />
                      </div>
                      <div
                        className="input-section"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label className="form-label">New email</label>
                        <input
                          onChange={(e) => setChangeEmail(e.target.value)}
                          type="text"
                          className="form-input"
                        />
                      </div>
                      <div
                        className="input-section"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label className="form-label">
                          Validez votre mot de passe pour changer d'adresse
                          e-mail
                        </label>
                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div style={{ paddingBottom: 40 }}>
                      <Button
                        type="primary"
                        style={{
                          borderRadius: 10,
                          backgroundColor: "red",
                          display: "flex",
                          height: 48,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => resetEmail(admin, changeEmail, password)}
                      >
                        envoyé
                      </Button>
                    </div>

                    {isLoading2 && (
                      <div
                        style={{
                          marginTop: "2rem",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          height: "5rem",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    )}

                    <div className="form-settings__section">
                      <div className="form-title">Change password</div>
                      <div className="ConatinerInput">
                        <div className="section2">
                          <label>
                            Appuyer pour recevoir un e-mail de réinitialiser
                            votre nouveau mot de passe
                          </label>
                        </div>
                        {/* <div className="input-section">
                          <input
                            type="text"
                            disabled={true}
                            placeholder={`${admin?.email}`}
                            // onChange={(e) => setEmail(e.target.value)}

                            className="form-input"
                          />
                        </div> */}
                      </div>
                    </div>
                    <Button
                      type="primary"
                      style={{
                        borderRadius: 10,
                        backgroundColor: "red",
                        display: "flex",
                        height: 48,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => resetPassword(admin.email)}
                    >
                      envoyé
                    </Button>

                    {isLoading && (
                      <div
                        style={{
                          marginTop: "2rem",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          height: "5rem",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    )}

                    {/* <div className="form-settings__section">
                      <div className="form-title">
                        Two Factor Authentication
                      </div>

                      <div className="input-section">
                        <div className="input-group input-switch">
                          <label className="form-label">
                            Enable or disable two-factor authentication
                          </label>
                          <input type="checkbox" className="form-input" />
                          <div></div>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}
              </form>
              {/* {this.state.form === "notifications" && (
                  <form className="form form-settings">
                    <div className="form-settings__section">
                      <div className="form-title">Account Notifications</div>

                      <div className="input-section">
                        <div className="input-group input-switch">
                          <label className="form-label">
                            Enable all account notifications
                          </label>
                          <input type="checkbox" className="form-input" />
                          <div></div>
                        </div>
                      </div>

                      <div className="input-section">
                        <div className="input-group input-switch">
                          <label className="form-label">
                            Enable Browser notifications
                          </label>
                          <input type="checkbox" className="form-input" />
                          <div></div>
                        </div>
                      </div>

                      <div className="input-section">
                        <div className="input-group input-switch">
                          <label className="form-label">
                            Enable Chat notifications
                          </label>
                          <input type="checkbox" className="form-input" />
                          <div></div>
                        </div>
                      </div>

                      <div className="input-section">
                        <div className="input-group input-switch">
                          <label className="form-label">
                            Driver Inactivity
                          </label>
                          <input type="checkbox" className="form-input" />
                          <div></div>
                        </div>
                      </div>
                    </div>

                    <div className="form-settings__section">
                      <div className="form-title">Email Notifications</div>

                      <div className="input-section">
                        <div className="input-group input-switch">
                          <label className="form-label">
                            Lorem ipsum dolor sit amet
                          </label>
                          <input type="checkbox" className="form-input" />
                          <div></div>
                        </div>
                      </div>

                      <div className="input-section">
                        <div className="input-group input-switch">
                          <label className="form-label">
                            Lorem ipsum dolor sit amet
                          </label>
                          <input type="checkbox" className="form-input" />
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
                {this.state.form === "chat" && (
                  <form className="form form-settings">
                    <div className="form-settings__section">
                      <div className="form-title">Chat Status</div>

                      <div className="input-group-inline-check">
                        <div style={{ color: "#ADAEB8", fontSize: "14px" }}>
                          Status appeareance
                        </div>

                        <div className="input-section">
                          <div className="input-group input-switch">
                            <input type="checkbox" className="form-input" />
                            <div></div>
                            <label className="form-label">Online</label>
                          </div>

                          <div className="input-group input-switch">
                            <input type="checkbox" className="form-input" />
                            <div></div>
                            <label className="form-label">Offline</label>
                          </div>

                          <div className="input-group input-switch">
                            <input type="checkbox" className="form-input" />
                            <div></div>
                            <label className="form-label">Away</label>
                          </div>

                          <div className="input-group input-switch">
                            <input type="checkbox" className="form-input" />
                            <div></div>
                            <label className="form-label">Busy</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
