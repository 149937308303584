import React, { Component, useEffect, useState } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import folder_2_icon from "../../assets/images/folder_2_icon.svg";
import avatar_2 from "../../assets/images/avatar_2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Spin, Switch } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { Field, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";

const Profile = () => {
  const { state } = useLocation();
  const { user, type } = state;
  const navigate = useNavigate();
  const [isModifying, setModifying] = useState(false);
  const [isModifyingRol, setModifyingRol] = useState(false);
  const [form, setForm] = useState("default");
  const [isLoading, setLoading] = useState(false);
  const [nameAux, setNameAux] = useState("");
  const [roles, setRoles] = useState({
    createTicket: false,
    scheduleTicket: false,
    createDriver: false,
    modifyDriver: false,
    deleteDriver: false,
    createClient: false,
    modifyClient: false,
    deleteClient: false,
  });

  const updateUser = async (dataEdit) => {
    const docUpdate = doc(db, type, user.uid);
    await updateDoc(docUpdate, dataEdit)
      .then(() => setNameAux(dataEdit.firstName + " " + dataEdit.lastName))
      .catch((err) => {
        toast.error("Error");
        console.log(err.message);
      });
  };

  const handleModify = async (values) => {
    if (!isModifying) return setModifying(true);
    try {
      setLoading(true);
      await updateUser(values);
      setModifying(false);
      toast.success("User updated!");
    } catch (error) {
      console.log(error);
      toast.error("Error");
    } finally {
      setLoading(false);
    }
  };

  const handleModifyRoles = async () => {
    if (!isModifyingRol) return setModifyingRol(true);
    try {
      setLoading(true);
      await updateUser(roles);
      setModifyingRol(false);
      toast.success("User updated!");
    } catch (error) {
      console.log(error);
      toast.error("Error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === "drivers" && user) {
      setRoles({
        createTicket: user.createTicket,
        scheduleTicket: user.scheduleTicket,
        createDriver: user.createDriver,
        modifyDriver: user.modifyDriver,
        deleteDriver: user.deleteDriver,
        createClient: user.createClient,
        modifyClient: user.modifyClient,
        deleteClient: user.deleteClient,
      });
    }
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />
      <ToastContainer />
      <div className="grid grid--dashboard">
        <Menu />
        <div className="container container--dashboard">
          <div className="flex-double no-center">
            <div className="flex-double__l w-35">
              <div className="archive-hierarchy">
                <div
                  className="archive-hierarchy__item pointer"
                  onClick={() => setForm("default")}
                >
                  <div className="label-group">
                    <img
                      src={folder_2_icon}
                      alt="Folder icon"
                      title="Folder icon"
                    />
                    Profile
                  </div>
                </div>

                <div
                  className="archive-hierarchy__item pointer"
                  onClick={() => setForm("roles")}
                >
                  <div className="label-group">
                    <img
                      src={folder_2_icon}
                      alt="Folder icon"
                      title="Folder icon"
                    />
                    Gestion des rôles
                  </div>
                </div>

                {/* <div
                  className="archive-hierarchy__item pointer"
                  onClick={() => setForm("history")}
                >
                  <div className="label-group">
                    <img
                      src={folder_2_icon}
                      alt="Folder icon"
                      title="Folder icon"
                    />
                    Historique
                  </div>
                </div> */}
              </div>
            </div>
            <div className="flex-double__r w-65">
              {form === "default" && (
                <div className="form form-settings">
                  <div className="form">
                    <div className="form_header">
                      <div className="form_profile">
                        <div className="form_avatar">
                          <img src={user?.profile_picture_url} />
                        </div>

                        <div>
                          <span class="__name">
                            {nameAux !== ""
                              ? nameAux
                              : user
                              ? user.firstName + " " + user.lastName
                              : "Prenom"}
                          </span>
                          <div className="status offline">
                            {user?.activity?.type === "login"
                              ? "online"
                              : "offline"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: user?.firstName ? user.firstName : "",
                      lastName: user?.lastName ? user.lastName : "",
                      vehicle: user?.vehicule ? user.vehicule : "",
                      email: user?.email ? user.email : "",
                      plaqueField: user?.plaqueField ? user.plaqueField : "",
                      telephone: user?.telephone ? user.telephone : "",
                    }}
                    onSubmit={(values) => handleModify(values)}
                  >
                    {({
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      values,
                      errors,
                    }) => (
                      <div className="form-settings__section">
                        <div className="form-title">
                          Informations du livreur
                        </div>

                        <div className="input-section">
                          <div className="input-group">
                            <label className="form-label">Nom</label>
                            <Field
                              type="text"
                              name="firstName"
                              placeholder="Nom"
                              //defaultValue={user?.firstName}
                              className="form-input"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              disabled={!isModifying}
                            />
                          </div>

                          <div className="input-group">
                            <label className="form-label">Vehicle</label>
                            <Field
                              type="text"
                              name="vehicle"
                              placeholder="Vehicle"
                              className="form-input"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              disabled={!isModifying}
                            />
                          </div>
                        </div>

                        <div className="input-section">
                          <div className="input-group">
                            <label className="form-label">Prenom</label>
                            <Field
                              type="text"
                              placeholder="Prenom"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              name="lastName"
                              className="form-input"
                              disabled={!isModifying}
                            />
                          </div>

                          <div className="input-group">
                            <label className="form-label">
                              Plaque d'immatriculation
                            </label>
                            <Field
                              defaultValue={user?.plaqueField}
                              type="text"
                              placeholder="AA-123-AA"
                              name="plaqueField"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              className="form-input"
                              disabled={!isModifying}
                            />
                          </div>
                        </div>

                        <div className="input-section">
                          <div className="input-group">
                            <label className="form-label">Email</label>
                            <Field
                              defaultValue={user?.email}
                              type="text"
                              placeholder="Email"
                              className="form-input"
                              name="email"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              disabled={!isModifying}
                            />
                          </div>

                          <div className="input-group">
                            <label className="form-label">Telephone</label>
                            <Field
                              type="numeric"
                              name="telephone"
                              placeholder="123456789"
                              className="form-input"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              disabled={!isModifying}
                            />
                          </div>
                        </div>
                        <Button
                          type="primary"
                          style={{
                            borderRadius: 10,
                            backgroundColor: "#FF4E4E",
                            borderColor: "#FF4E4E",
                            width: "100%",
                            height: "48px",
                            //   fontFamily: "SegoeUI",
                            fontSize: "20px",
                          }}
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          {isModifying
                            ? "Enregistrer Modification"
                            : "Modifier"}
                        </Button>
                      </div>
                    )}
                  </Formik>
                  {isLoading && (
                    <div
                      style={{
                        marginTop: "2rem",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        height: "4rem",
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  )}
                </div>
              )}
              {form === "history" && (
                <form className="form form-settings">
                  <div className="form">
                    <div className="form_header">
                      <div className="form_profile">
                        <div className="form_avatar">
                          <img src={avatar_2} />
                        </div>

                        <div>
                          <span class="__name">H. Blue Cross</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-settings__section">
                    <div className="form-title">
                      Information de l'Establishment
                    </div>

                    <div className="input-section">
                      <div className="input-group">
                        <label className="form-label">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          className="form-input"
                        />
                      </div>

                      <div className="input-group">
                        <label className="form-label">Entreprise</label>
                        <input
                          type="text"
                          placeholder="Entreprise"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div className="input-group">
                        <label className="form-label">Entreprise</label>
                        <input
                          type="text"
                          placeholder="Entreprise"
                          className="form-input"
                        />
                      </div>

                      <div className="input-group">
                        <label className="form-label">
                          Plaque d'immatriculation
                        </label>
                        <input
                          type="text"
                          placeholder="AA-123-AA"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div className="input-group">
                        <label className="form-label">Email</label>
                        <input
                          type="text"
                          placeholder="Email"
                          className="form-input"
                        />
                      </div>

                      <div className="input-group">
                        <label className="form-label">Telephone</label>
                        <input
                          type="text"
                          placeholder="123456789"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div className="input-group">
                        <label className="form-label">Mobile</label>
                        <input
                          type="text"
                          placeholder="123456789"
                          className="form-input"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-settings__section">
                    <div className="form-title">Adressse de facturation</div>

                    <div className="input-section __full">
                      <div className="input-group">
                        <label className="form-label">Address line 1</label>
                        <input
                          type="text"
                          placeholder="Address line 1"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div className="input-section __full">
                      <div className="input-group">
                        <label className="form-label">Address line 2</label>
                        <input
                          type="text"
                          placeholder="Address line 2"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div className="input-section __full">
                      <div className="input-group">
                        <label className="form-label">Address line 3</label>
                        <input
                          type="text"
                          placeholder="Address line 3"
                          className="form-input"
                        />
                      </div>
                    </div>
                    <div className="input-section">
                      <div className="input-group">
                        <label className="form-label">Ville</label>
                        <input
                          type="text"
                          placeholder="Ville"
                          className="form-input"
                        />
                      </div>

                      <div className="input-group">
                        <label className="form-label">Departement</label>
                        <input
                          type="text"
                          placeholder="Departement"
                          className="form-input"
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div className="input-group">
                        <label className="form-label">Code Postal</label>
                        <input
                          type="text"
                          placeholder="Code Postal"
                          className="form-input"
                        />
                      </div>

                      <div className="input-group">
                        <label className="form-label">Pays</label>
                        <input
                          type="text"
                          placeholder="Pays"
                          className="form-input"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {form === "roles" && type === "drivers" && (
                <form className="form form-settings">
                  <div className="form">
                    <div className="form_header">
                      <div className="form_profile">
                        <div className="form_avatar">
                          <img src={user?.profile_picture_url} />
                        </div>

                        <div>
                          <span class="__name">
                            {user
                              ? user.firstName + " " + user.lastName
                              : "Prenom"}
                          </span>
                          <div className="status offline">
                            {user?.activity?.type === "login"
                              ? "online"
                              : "offline"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-settings__section">
                    <div className="form-title">Roles</div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à créer un ticket
                        </label>
                        <Switch
                          style={
                            roles.createTicket ? { backgroundColor: "red" } : {}
                          }
                          checked={roles.createTicket}
                          disabled={!isModifyingRol}
                          onChange={(checked) =>
                            setRoles({ ...roles, createTicket: checked })
                          }
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à programmer un ticket
                        </label>
                        <Switch
                          defaultChecked
                          style={
                            roles.scheduleTicket
                              ? { backgroundColor: "red" }
                              : {}
                          }
                          checked={roles.scheduleTicket}
                          disabled={!isModifyingRol}
                          onChange={(checked) =>
                            setRoles({ ...roles, scheduleTicket: checked })
                          }
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à créer un nouveau livreur
                        </label>
                        <Switch
                          defaultChecked
                          style={
                            roles.createDriver ? { backgroundColor: "red" } : {}
                          }
                          checked={roles.createDriver}
                          disabled={!isModifyingRol}
                          onChange={(checked) =>
                            setRoles({ ...roles, createDriver: checked })
                          }
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à modifier un livreur
                        </label>
                        <Switch
                          defaultChecked
                          style={
                            roles.modifyDriver ? { backgroundColor: "red" } : {}
                          }
                          checked={roles.modifyDriver}
                          disabled={!isModifyingRol}
                          onChange={(checked) =>
                            setRoles({ ...roles, modifyDriver: checked })
                          }
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à supprimer un livreur
                        </label>
                        <Switch
                          defaultChecked
                          style={
                            roles.deleteDriver ? { backgroundColor: "red" } : {}
                          }
                          checked={roles.deleteDriver}
                          disabled={!isModifyingRol}
                          onChange={(checked) =>
                            setRoles({ ...roles, deleteDriver: checked })
                          }
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à ajouter un nouvel établissement
                        </label>
                        <Switch
                          defaultChecked
                          style={
                            roles.createClient ? { backgroundColor: "red" } : {}
                          }
                          disabled={!isModifyingRol}
                          checked={roles.createClient}
                          onChange={(checked) =>
                            setRoles({ ...roles, createClient: checked })
                          }
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à modifier un établissement
                        </label>
                        <Switch
                          defaultChecked
                          style={
                            roles.modifyClient ? { backgroundColor: "red" } : {}
                          }
                          disabled={!isModifyingRol}
                          checked={roles.modifyClient}
                          onChange={(checked) =>
                            setRoles({ ...roles, modifyClient: checked })
                          }
                        />
                      </div>
                    </div>

                    <div className="input-section">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          className="form-label"
                          style={{ color: "#3c3c3c" }}
                        >
                          Autoriser à supprimer un établissement
                        </label>
                        <Switch
                          defaultChecked
                          style={
                            roles.deleteClient ? { backgroundColor: "red" } : {}
                          }
                          disabled={!isModifyingRol}
                          checked={roles.deleteClient}
                          onChange={(checked) =>
                            setRoles({ ...roles, deleteClient: checked })
                          }
                        />
                      </div>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        borderRadius: 10,
                        backgroundColor: "#FF4E4E",
                        borderColor: "#FF4E4E",
                        width: "100%",
                        height: "48px",
                        //   fontFamily: "SegoeUI",
                        fontSize: "20px",
                      }}
                      disabled={isLoading}
                      onClick={handleModifyRoles}
                    >
                      {isModifyingRol ? "Enregistrer Modification" : "Modifier"}
                    </Button>
                    {isLoading && (
                      <div
                        style={{
                          marginTop: "2rem",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          height: "4rem",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
