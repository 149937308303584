import React, { useState, useEffect } from "react";

import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";
import Metadata from "../../components/Metadata";
import Paper from "../../assets/images/paper.svg";
import Close from "../../assets/images/close.svg";
import Truck from "../../assets/images/truck.png";
import Centerr from "../../assets/images/center.svg";
import Refresh from "../../assets/images/refresh.svg";
import CardProgressBar from "../../components/dashboard/CardProgressBar";
import { useLocation } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import moment from "moment";
import { Spin } from "antd";

const Tracking = () => {
  const location = useLocation();
  const { id, idDriver, data, state1, state2 } = location.state;

  const [isMenu, setIsMenu] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [driver, setDriver] = useState([]);
  const [ticketDriver, setTicketDriver] = useState([]);
  const [FilesToShowIndex, setFilesToShowIndex] = useState();
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [center, setCenter] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [loader, setLoader] = useState(false);

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBqwwHc7bAGFG3P21C0xMPvsTyT7aSLj34",
  });

  const getDriver = async (id) => {
    try {
      if (id === undefined) {
        getTicket(data);
      } else {
        const docRef = doc(db, "drivers", id);
        const docSnap = await getDoc(docRef);
        setDriver(docSnap.data());
        getTicket(docSnap.data());
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getTicket = async (driver) => {
    try {
      const docRef = doc(db, "tickets", id);
      const docSnap = await getDoc(docRef);
      setTicketDriver(docSnap.data());
      calculateRouteReception(docSnap.data(), driver);
      setLoader(true);
    } catch (e) {
      console.error(e);
    }
  };

  const getFileDownloadUrl = async (file) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, file);
      const url = await getDownloadURL(imageRef);

      window.open(url, "_blank");
    } catch (e) {
      console.error(e);
    }
  };

  async function calculateRouteReception(data, driver) {
    const directionsService = new window.google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: new window.google.maps.LatLng(
        driver?.location?.latitude,
        driver?.location?.longitude
      ),
      destination:
        data?.step === "completed"
          ? new window.google.maps.LatLng(
              driver?.location?.latitude,
              driver?.location?.longitude
            )
          : data?.step === "reception"
          ? new window.google.maps.LatLng(
              data?.reception?.location?.region?.latitude,
              data?.reception?.location?.region?.longitude
            )
          : new window.google.maps.LatLng(
              data?.deposer?.location?.region?.latitude,
              data?.deposer?.location?.region?.longitude
            ),

      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setCenter(
      new window.google.maps.LatLng(
        driver?.location?.latitude,
        driver?.location?.longitude
      )
    );
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  useEffect(() => {
    getDriver(idDriver);
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />

      <div className="grid grid--dashboard">
        <Menu />
        {!loader ? (
          <div
            style={{
              marginTop: "10rem",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "10rem",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div className="continer container--dashboard">
              <div className="flex-double">
                <div className="flex-double__l">
                  <h2
                    className="dashboard-title"
                    style={{ marginBottom: "30px" }}
                  >
                    Tracking
                  </h2>
                </div>
                <div className="flex-double __r flex-double__r">
                  <span
                    className="dashboard-title dashboard-title__sub-2"
                    style={{ margin: "0 10px 0 0" }}
                  >
                    Ticket Nº {ticketDriver?.ticketNo}
                  </span>
                  <div
                    className="button button--default"
                    onClick={() => setTicket(!ticket)}
                  >
                    See Ticket
                  </div>
                </div>
              </div>

              <div className="App">
                {!isLoaded ? (
                  <h1>Loading...</h1>
                ) : (
                  <>
                    <GoogleMap
                      mapContainerClassName="map-container"
                      center={
                        new window.google.maps.LatLng(
                          driver?.location?.latitude,
                          driver?.location?.longitude
                        )
                      }
                      zoom={15}
                      options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                      onLoad={(map) => setMap(map)}
                    >
                      <Marker
                        position={{
                          lat: driver?.location?.latitude,
                          lng: driver?.location?.longitude,
                        }}
                        icon={Truck}
                      />

                      {directionsResponse &&
                        ticketDriver?.step !== "completed" && (
                          <DirectionsRenderer directions={directionsResponse} />
                        )}
                    </GoogleMap>

                    <div className="Container">
                      <img
                        className="hover"
                        src={Centerr}
                        alt="centerIcon"
                        onClick={() => map.panTo(center)}
                      />
                      <div>distance: {distance}</div>
                      <div className="hover__right">duration: {duration}</div>
                      <img
                        className="hover hover__right"
                        src={Refresh}
                        alt="refreshIcon"
                        onClick={() => getDriver(idDriver)}
                      />
                    </div>
                  </>
                )}
              </div>

              <div style={{ margin: "0 -25px 25px -25px" }}>
                <CardProgressBar data={ticketDriver} />
              </div>
              <div className="flex-double no-center">
                <div className="flex-double__l w-35">
                  <h2 className="dashboard-title2 dashboard-title2__sub-1">
                    Summary
                  </h2>

                  <div className="profile" style={{ paddingBottom: "32px" }}>
                    {ticketDriver?.urgence === "Urgence" ? (
                      <>
                        <div className="dot2" />
                      </>
                    ) : ticketDriver?.urgence === "Relative" ? (
                      <>
                        <div className="dot" />
                      </>
                    ) : (
                      <>
                        <div className="dot3" />
                      </>
                    )}
                    <div className="dashboard-title2__sub-2">
                      Urgence: {ticketDriver?.urgence}
                    </div>
                  </div>

                  <div className="box-card2">
                    <div className="box-card2__header">
                      <div
                        className={
                          idDriver === undefined
                            ? "t-button2__gray "
                            : ticketDriver?.status === "inProgress"
                            ? "t-button2"
                            : "t-button2__green "
                        }
                      >
                        {idDriver === undefined
                          ? "En Attente"
                          : ticketDriver?.status === "inProgress"
                          ? "On Cours"
                          : "Delivered"}
                      </div>
                    </div>

                    <div className="__list">
                      <div className="__item flex-double2">
                        <div className="flex-double2__l w-auto">
                          <div className="profile">
                            {ticketDriver?.receptionDate ? (
                              <div className="__icongreen"></div>
                            ) : (
                              <div className="__icon"></div>
                            )}
                            <div className="card--medical__text-tip card--medical__text-tip__big">
                              {ticketDriver?.reception?.location.address}
                            </div>
                          </div>
                        </div>
                        <span className="card--medical__text-tip card--medical__text-tip__fade flex-double__r __r w-auto">
                          Arrival time |{" "}
                          {ticketDriver?.receptionDate
                            ? moment(
                                ticketDriver?.receptionDate.toDate()
                              ).format("HH:mm A")
                            : state1}
                        </span>
                      </div>

                      <div className="__item flex-double2">
                        <div className="flex-double2__l w-auto">
                          <div className="profile">
                            {ticketDriver?.depotDate ? (
                              <div className="__icongreen"></div>
                            ) : (
                              <div className="__icon"></div>
                            )}
                            <div className="card--medical__text-tip card--medical__text-tip__big">
                              {ticketDriver?.deposer?.location.address}
                            </div>
                          </div>
                        </div>
                        <span className="card--medical__text-tip card--medical__text-tip__fade flex-double__r __r w-auto">
                          Arrival time |{" "}
                          {ticketDriver?.depotDate
                            ? moment(ticketDriver?.depotDate.toDate()).format(
                                "HH:mm A"
                              )
                            : ticketDriver?.receptionDate
                            ? moment(ticketDriver?.receptionDate.toDate())
                                .add(15, "m")
                                .format("HH:mm A")
                            : state2}
                        </span>
                      </div>
                    </div>

                    {idDriver === undefined ? (
                      <div className="box-card2__footer2">En Attente</div>
                    ) : ticketDriver?.step === "completed" ? (
                      <div className="box-card2__footer">Delivered</div>
                    ) : ticketDriver?.status === "completed" ? (
                      <div className="box-card2__footer">Delivered</div>
                    ) : (
                      <div className="box-card2__footer3">On Cours</div>
                    )}
                  </div>
                </div>

                <div className="flex-double__r w-65">
                  <h2 className="dashboard-title dashboard-title__sub-1">
                    Product to deliver
                  </h2>
                  <div className="box-card">
                    <div className="box-card__header">
                      <div className="products-columns">
                        <div className="first">Type/Nature</div>

                        <div className="second">Attached files</div>

                        <div className="third">Total</div>
                      </div>
                    </div>

                    <div className="__list no-footer">
                      {ticketDriver?.transports.map((item, key) => (
                        <>
                          <div className="__item products-columns">
                            <div className="first">
                              {item?.product?.name || "Sanguin"}
                            </div>

                            <div style={{ display: "flex" }}>
                              <img
                                className="hover"
                                src={Paper}
                                alt="PaperIcon"
                                onClick={() => {
                                  setFilesToShowIndex(key);
                                  setIsMenu(!isMenu);
                                }}
                              />
                              <div className="second">
                                {item?.files?.length}
                              </div>
                            </div>
                            <div className="third">{item?.quantity}</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isMenu && (
              <div className="menu">
                <ul>
                  <div style={{ display: "flex" }}>
                    <li className="menu__title">Archvie</li>
                    <img
                      src={Close}
                      alt="CloseIcon"
                      onClick={() => setIsMenu(!isMenu)}
                      style={{ right: 10, top: 10, position: "absolute" }}
                      className="menu__optionHover "
                    />
                  </div>
                  <div>
                    <>
                      {ticketDriver?.transports[FilesToShowIndex]?.files.map(
                        (file, index) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "white",
                                paddingRight: 20,
                                paddingTop: 15,
                              }}
                            >
                              File: {index + 1}
                            </div>
                            <img
                              src={Paper}
                              alt="PaperIcon"
                              onClick={() => getFileDownloadUrl(file)}
                              style={{ paddingTop: 15 }}
                              className="menu__optionHover "
                            />
                          </div>
                        )
                      )}
                    </>
                  </div>
                </ul>
              </div>
            )}

            {ticket && (
              <div className="menu2">
                <ul>
                  <div style={{ display: "flex" }}>
                    <li className="menu2__title">Ticket Summary</li>
                    <img
                      src={Close}
                      alt="CloseIcon"
                      onClick={() => setTicket(!ticket)}
                      style={{ right: 10, top: 10, position: "absolute" }}
                      className="menu2__optionHover "
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: "white",
                      paddingRight: 10,
                      marginTop: 10,
                    }}
                  />
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <>
                      {ticketDriver?.transports.map((item, index) => (
                        <>
                          <div className="menu2__subtitle">
                            Livreur: {ticketDriver?.driver?.name}
                          </div>

                          <div className="menu2__subtitle">
                            Heure de creation: {ticketDriver?.time?.accepted_at}
                          </div>

                          <div className="menu2__subtitle">
                            Enlevement: {ticketDriver?.client?.name}
                          </div>

                          <div className="menu2__subtitle">
                            Heure d'arrivee: {item?.receptionAgent?.date}
                          </div>

                          <div className="menu2__subtitle">
                            Depot: {ticketDriver?.deposer?.location?.address}
                          </div>

                          <div className="menu2__subtitle">
                            Heure d'arrivee: {item?.deposerAgent?.date}
                          </div>

                          <div className="menu2__subtitle">
                            Nature: {item?.product?.name}
                          </div>

                          <div className="menu2__subtitle">
                            Quantity: {item?.quantity}
                          </div>

                          <div className="menu2__subtitle">
                            Patient: {item?.patient}
                          </div>

                          <div className="menu2__subtitle">
                            Sexe: {item?.genre}
                          </div>

                          <div
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: "white",
                              paddingRight: 10,
                              marginTop: 10,
                            }}
                          />
                        </>
                      ))}
                    </>
                  </div>
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Tracking;
