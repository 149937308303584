import React, { Component } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import action_dots_icon from "../../assets/images/action_dots_icon.svg";
import arrow_down_22_icon from "../../assets/images/arrow_down_22_icon.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";

class Reminder extends Component {
  render() {
    return (
      <div className="wrapper wrapper--light">
        <Metadata title="Dashboard - Tracking" />
        <Header />

        <div className="grid grid--dashboard">
            <Menu />
          <div className="container container--dashboard">
          <h2 className="dashboard-title" style={{ textAlign: 'center' }}>Reminder</h2>
            <form className="form form-reminder">
                <div className="form-settings__section">
                    
                    <div className="input-section __full">
                        <div className="input-group">
                            <label className="form-label">Driver</label>
                            <input type="text" placeholder="Search Establishment" className="form-input" />
                        </div>
                    </div>

                    <div className="input-section __full">
                        <div className="input-group">
                            <label className="form-label">Urgence</label>
                            <input type="text" placeholder="Sélectionner nature" className="form-input form-input-br-btm" />
                        </div>
                    </div>


                    <div className="input-section date-pick">
                        <div className="input-group">
                            <label className="form-label">Date</label>
                            <input type="text" placeholder="DD / MM / AAAA" className="form-input" />
                        </div>

                        <div className="input-group" style={{margin: '0 20px'}}>
                            <label className="form-label">Hour</label>
                            <input type="text" placeholder="00:00" className="form-input" />
                        </div>

                        <div className="input-group">
                            <label className="form-label">Repetitions</label>
                            <input type="text" placeholder="No repetitions" className="form-input form-input-br-btm" />
                        </div>
                    </div>


                    <div className="input-section __full">
                        <div className="input-group">
                            <label className="form-label">Description</label>
                            <textarea className="form-input textarea"></textarea>
                        </div>
                    </div>

                    <div className="input-section">
                        <div className="input-group submit flex-double">
                            <button className="button button--default">Cancel</button>
                            <button className="button button--default">Save</button>
                        </div>
                    </div>
                </div>
            </form>
          </div>
        </div>

      </div>
    );
  }
}

export default Reminder;
