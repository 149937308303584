import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/mdkd_logo.svg";
import message_people_icon from "../../assets/images/message_people_icon.svg";
import half_arrow_up_icon from "../../assets/images/half_arrow_up_icon.svg";
import cross_close from "../../assets/images/cross_close.svg";
import avatar_2 from "../../assets/images/avatar_2.png";
import attachment_icon from "../../assets/images/attachment_icon.svg";
import send_message_icon from "../../assets/images/send_message_icon.svg";

export default class MessageBox extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
        <div className="message-box">
            <header className="message-box_header">
                <div className="message-box_profile">
                    <div className="message-box_avatar">
                        <img
                            src={avatar_2}
                            title="Message Avatar"
                            alt="Message Avatar"
                        />
                    </div>
                    <div>
                        <div className="message-box_name">Nom du driver</div>
                        <div className="message-box_status __online">Online</div>
                    </div>
                </div>

                <div>
                    <div className="message-box_tools">
                        <div className="__tool">
                            <img
                                src={message_people_icon}
                                title="Message someone"
                                alt="Message someone"
                            />
                        </div>

                        <div className="__tool">
                            <img
                                src={half_arrow_up_icon}
                                title="Toggle the message box"
                                alt="Toggle the message box"
                            />
                        </div>

                        <div className="__tool">
                            <img
                                src={cross_close}
                                title="Close the message box"
                                alt="Close the message box"
                            />
                        </div>
                    </div>
                </div>

            </header>

            <div className="message-box_body">
                <div className="message-box_message __driver">
                    <p className="__date">Date et heure</p>
                    <p className="__message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>

                <div className="message-box_message __delivery">
                    <div className="__head">
                        <p className="__name">Nom du livreur</p>
                        <p className="__date">Date et heure</p>
                    </div>
                    <p className="__message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>

                <div className="message-box_message __driver">
                    <p className="__date">Date et heure</p>
                    <p className="__message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>

                <div className="message-box_message __delivery">
                    <div className="__head">
                        <p className="__name">Nom du livreur</p>
                        <p className="__date">Date et heure</p>
                    </div>
                    <p className="__message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>

                <div className="message-box_message __delivery">
                    <div className="__head">
                        <p className="__name">Nom du livreur</p>
                        <p className="__date">Date et heure</p>
                    </div>
                    <p className="__message">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
            </div>

            <footer className="message-box_footer">
                <div>
                    <img
                        src={attachment_icon}
                        alt="Attachment"
                        title="Attachment"
                    />
                </div>
                <input type="text" />
                <div>
                    <img
                        src={send_message_icon}
                        alt="Send the message"
                        title="Send the message"
                    />
                </div>
            </footer>
        </div>
    );
  }
}
