import React, { useEffect, useState } from "react";

import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";
import Metadata from "../../components/Metadata";
import arrow_down_22_icon from "../../assets/images/arrow_down_22_icon.svg";
import closee from "../../assets/images/closee.svg";
import search_icon from "../../assets/images/search_icon.svg";
import logout_icon from "../../assets/images/logout_icon.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import calendar from "../../assets/images/calendar.svg";

import { db } from "../../config/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  limit,
} from "firebase/firestore";
import { Spin, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PlusOutlined } from "@ant-design/icons";
import TicketModal from "../../components/modals/TicketModal";

const Ticket = () => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const [ticket, setTciket] = useState([]);
  const [ticketSearch, setTciketSearch] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [filteredTicket, setFilteredTicket] = useState([]);
  const [filteredTicketCalendar, setFilteredTicketCalendar] = useState([]);
  const [modal, setModal] = useState(false);
  const [minDate2, setMinDate2] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState(false);
  const [search, setSearch] = useState(false);
  const [isOpenCreateModal, setOpenCreateModal] = useState(false);

  const navigate = useNavigate();

  // GET THE INITIAL LIST OF TICKETS
  const getTicket = async () => {
    try {
      setLoading(true);
      const arrayResult = [];
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const querySnapshot = await getDocs(
        query(
          collection(db, "tickets"),
          where("createdAt", ">", date),
          orderBy("createdAt", "desc"),
          limit(200)
        )
      );

      querySnapshot.forEach((doc) => {
        arrayResult.push({ ...doc.data(), uid: doc.id });
      });
      setTciket(arrayResult);
    } catch (error) {
      toast.error(error.message);
      console.log("===== Error from catch =======");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // FILTER BY INPUT A NUMBER
  const filterTicketByNumber = async () => {
    try {
      var arrayResult = [];
      setLoading(true);
      setSearch(false);
      const q = query(
        collection(db, "tickets"),
        where("ticketNo", "==", parseInt(ticketSearch))
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arrayResult.push({ ...doc.data(), uid: doc.id });
      });
      setFilteredTicket(arrayResult);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      filterTicketByNumber();
    }
  };

  const activeTicket = async (id) => {
    try {
      const docRef = doc(db, "tickets", id);
      const docSnap = await getDoc(docRef);
      var uid = docSnap.id;

      navigateTicket(docSnap.data(), uid);
    } catch (e) {
      console.error(e);
    }
  };

  // NAVIGATE TO THE TICKET
  const navigateTicket = (item, uid) => {
    const id = uid;
    const idDriver = item?.driver?.id;
    const data = item;
    const state1 = moment(item?.createdAt?.toDate())
      .add(15, "m")
      .format("HH:mm A");
    const state2 = moment(item?.createdAt?.toDate())
      .add(30, "m")
      .format("HH:mm A");

    navigate(`/admin/tracking`, {
      state: { id, idDriver, data, state1, state2 },
    });
  };

  // FILTER BY DATES
  const getFilteredTickets = async () => {
    try {
      setLoading(true);
      setFilteredTicket([]);
      setTciketSearch("");
      const arrayResult = [];
      const snapshot = await getDocs(
        query(
          collection(db, "tickets"),
          where("createdAt", ">=", startDate),
          where("createdAt", "<=", endDate),
          orderBy("createdAt", "desc")
        )
      );

      snapshot.forEach((doc) => {
        const data = doc.data();
        arrayResult.push({
          uid: doc.id,
          ...data,
        });
      });

      setFilteredTicketCalendar(arrayResult);
    } catch (error) {
      toast.error(error.message);
      console.log("===== Error from catch =======");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTicket();
  }, []);

  useEffect(() => {
    let date = moment(startDate).add(1, "days");
    setMinDate2(date.toDate());
  }, [startDate]);

  return (
    <>
      <div className="wrapper wrapper--light">
        <Metadata title="Dashboard - Tracking" />
        <Header />
        <ToastContainer />
        <TicketModal
          isModalOpen={isOpenCreateModal}
          setOpenModal={() => setOpenCreateModal(!isOpenCreateModal)}
          refresh={() => getTicket()}
        />
        <div className="grid grid--dashboard">
          <Menu />
          <div className="container container--dashboard">
            <div className="container dashboard-header">
              <h2 style={{ width: "50%" }} className="dashboard-title">
                Tickets
              </h2>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  block
                  icon={<PlusOutlined />}
                  style={{
                    background: "#FF4E4E",
                    color: "white",
                    width: "200px",
                    height: "100%",
                  }}
                  onClick={() => setOpenCreateModal(!isOpenCreateModal)}
                >
                  Tickets
                </Button>
              </div>
            </div>

            <div className="archive-toolbar flex-double">
              <div className="searchbar-box">
                <input
                  type="number"
                  value={ticketSearch}
                  placeholder="Ticket Nº"
                  onChange={(e) => setTciketSearch(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={filterTicketByNumber}
                >
                  <img
                    src={search_icon}
                    alt="Search Bar Icon"
                    title="Search Bar Icon"
                    className="search-icon"
                  />
                </div>
              </div>
              <img
                src={filter_icon}
                alt="Filter Icon"
                className="hover"
                style={{ paddingLeft: 10 }}
                onClick={() => setModal(!modal)}
              />
            </div>

            {search && (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <div style={{ color: "#FF2222" }}>
                  {moment(startDate).format("DD/MM/YYYY")}{" "}
                </div>
                <div style={{ paddingLeft: 5, paddingRight: 5 }}>-</div>
                <div style={{ color: "#FF2222" }}>
                  {" "}
                  {moment(endDate).format("DD/MM/YYYY")}
                </div>
                <div>
                  <img
                    src={closee}
                    alt="CloseIcon"
                    onClick={() => {
                      setSearch(!search);
                      setEndDate("");
                      setStartDate("");
                    }}
                    className="hover "
                    style={{ marginTop: -5, marginLeft: 10 }}
                  />
                </div>
              </div>
            )}

            <div className="history-entries __header">
              <div className="history-entry5">
                <div className="profile">
                  Ticket No
                  <img
                    src={arrow_down_22_icon}
                    alt="Arrow down icon"
                    title="Arrow down icon"
                    style={{ transform: "rotate(90deg)", marginLeft: "10px" }}
                  />
                </div>
                <div>Status</div>
                <div>Driver Name</div>
                <div>Date</div>
                <div>View ticket</div>
              </div>
            </div>

            {isLoading ? (
              <div
                style={{
                  marginTop: "10rem",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  height: "10rem",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <>
                <div className="history-entries">
                  {!search ? (
                    <>
                      {(filteredTicket.length > 0
                        ? filteredTicket
                        : ticket
                      ).map((item, index) => (
                        <>
                          <div className="history-entry5" key={index}>
                            <div className="profile">
                              <div>{item?.ticketNo}</div>
                            </div>

                            <div
                              className={
                                item?.status === "initiated"
                                  ? "status attente "
                                  : item?.status === "inProgress"
                                  ? "status cours"
                                  : "status delivered"
                              }
                            >
                              {item?.status === "initiated"
                                ? "En Attente "
                                : item?.status === "inProgress"
                                ? "On Cours"
                                : "Delivered"}
                            </div>

                            <div style={{ paddingTop: 10 }}>
                              {item?.driver?.name ? (
                                item?.driver?.name
                              ) : (
                                <img
                                  style={{ paddingLeft: 50 }}
                                  src={closee}
                                  alt="ticketIcon"
                                />
                              )}
                            </div>
                            <div style={{ paddingTop: 10 }}>
                              {moment(item.createdAt?.toDate()).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                            <img
                              style={{ paddingLeft: 50, paddingTop: 10 }}
                              src={logout_icon}
                              alt="ticketIcon"
                              className="hover"
                              onClick={() => activeTicket(item.uid)}
                            />
                          </div>
                        </>
                      ))}
                    </>
                  ) : filteredTicketCalendar.length === 0 ? (
                    <h1
                      style={{
                        textAlign: "center",
                        paddingTop: 30,
                        color: "#ff4e4e",
                        fontSize: 30,
                      }}
                    >
                      No tickets found
                    </h1>
                  ) : (
                    <>
                      {(filteredTicket.length > 0
                        ? filteredTicket
                        : filteredTicketCalendar
                      ).map((item, index) => (
                        <>
                          <div key={index} className="history-entry5">
                            <div className="profile">
                              <div>{item?.ticketNo}</div>
                            </div>

                            <div
                              className={
                                item?.status === "initiated"
                                  ? "status attente "
                                  : item?.status === "inProgress"
                                  ? "status cours"
                                  : "status delivered"
                              }
                            >
                              {item?.status === "initiated"
                                ? "En Attente "
                                : item?.status === "inProgress"
                                ? "On Cours"
                                : "Delivered"}
                            </div>
                            <div style={{ paddingTop: 10 }}>
                              {item?.driver?.name ? (
                                item?.driver?.name
                              ) : (
                                <img
                                  style={{ paddingLeft: 50 }}
                                  src={closee}
                                  alt="ticketIcon"
                                />
                              )}
                            </div>
                            <div style={{ paddingTop: 10 }}>
                              {moment(item.createdAt?.toDate()).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                            <img
                              style={{ paddingLeft: 50, paddingTop: 10 }}
                              src={logout_icon}
                              alt="ticketIcon"
                              className="hover"
                              onClick={() => activeTicket(item.uid)}
                            />
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {modal && (
        <div className="menu3">
          <ul>
            <div style={{ display: "flex" }}>
              <li className="menu2__title">Filter</li>
              <img
                src={closee}
                alt="CloseIcon"
                onClick={() => {
                  setModal(!modal);
                  setStartDate("");
                  setEndDate("");
                }}
                style={{ right: 10, top: 10, position: "absolute" }}
                className="menu2__optionHover "
              />
            </div>
            <div
              style={{
                width: "100%",
                height: 1,
                backgroundColor: "white",
                paddingRight: 10,
                marginTop: 10,
              }}
            />
            <div style={{ textAlign: "center", paddingTop: 10 }}>
              Start day of search ticket:
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ paddingLeft: 120, paddingTop: 10 }}>
                <DatePicker
                  dateFormat="dd/MM/yy"
                  placeholderText="Press here"
                  selected={startDate}
                  onChange={(value) => {
                    var date = new Date(value);
                    setStartDate(date);
                  }}
                />
              </div>
              <img
                src={calendar}
                alt="CalendarIcon"
                style={{ left: 390, top: 105, position: "absolute" }}
              />
            </div>
            <div style={{ textAlign: "center", paddingTop: 10 }}>
              End day of search ticket:
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ paddingLeft: 120, paddingTop: 10 }}>
                <DatePicker
                  dateFormat="dd/MM/yy"
                  placeholderText="Press here"
                  selected={endDate}
                  minDate={startDate ? minDate2 : today}
                  onChange={(value) => {
                    var endDate = new Date(value);
                    setEndDate(endDate);
                  }}
                />
              </div>
              <img
                src={calendar}
                alt="CalendarIcon"
                style={{ left: 390, top: 175, position: "absolute" }}
              />
            </div>

            <div
              style={{
                paddingTop: 20,
                display: "grid",
                paddingRight: 20,
                paddingLeft: 20,
              }}
            >
              <Button
                onClick={() => {
                  {
                    setModal(!modal);
                    setDate(!date);
                    setSearch(!search);
                    getFilteredTickets();
                  }
                }}
              >
                Seacrh
              </Button>
            </div>
          </ul>
        </div>
      )}
    </>
  );
};

export default Ticket;
