import Navigation from "../src/navigation/index";
import UserState from "./context/User/UserState";

export default function App() {
  return (
    <UserState>
      <Navigation />
    </UserState>
  );
}
