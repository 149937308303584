import { LOGOUT_USER, SET_ISLOGGED, UPDATE_USER } from "../types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_ISLOGGED:
      return {
        ...state,
        isLogged: payload,
      };
    default:
      return state;
  }
};
