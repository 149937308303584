import React, { Component } from "react";
import "../../assets/styles/styles.scss";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import Metadata from "../../components/Metadata";
import logo from "../../assets/images/mdkd_logo.svg";

class Privacy extends Component {
  render() {
    return (
      <div>
        <Metadata title="Politique de confidentialité" />
        <header className="header-cgu">
          <div className="container">
            <Link to="/">
              <img src={logo} className="logo" />
            </Link>
            <h2 className="cgu-title">Politique de confidentialité</h2>
          </div>
        </header>

        <div className="container">
          <div className="cgu-section">
            <h3 className="cgu-section__title">Avertissements</h3>

            <div className="cgu-section__group">
              <div>
                <p className="cgu-article">
                  Le présent outil est mis à votre disposition gratuitement.
                  L’outil se base sur des informations fondées sur l’analyse
                  professionnelle du cabinet en matière de conformité RGPD.
                  Toutefois, la conformité étant un processus dynamique et toute
                  situation étant particulière, les informations transmises
                  doivent être adaptées et ne peuvent en aucun cas être
                  considérées comme exhaustives ou exactes.
                </p>

                <div className="cgu-article">
                  Sauf à ce que vous demandiez une revue et validation par le
                  Cabinet, le document généré est considéré comme une simple
                  information. En conséquence, vous êtes seul responsable des
                  interprétations faites des informations fournies, des conseils
                  que vous en déduisez et des adaptations réalisées pour votre
                  activité commerciale propre. L’utilisation et l’exploitation
                  de l’outil se fait donc sous votre seule responsabilité et à
                  vos risques et périls.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">Définitions</h3>

            <div className="cgu-section__group">
              <div>
                <p className="cgu-article">
                  <span className="font-bold">L’Éditeur</span> : La personne,
                  physique ou morale, qui édite les services de communication au
                  public en ligne
                </p>

                <p className="cgu-article">
                  <span className="font-bold">Le site</span> : L’ensemble des
                  sites, pages Internet et services en ligne proposés par
                  l’Éditeur.
                </p>

                <p className="cgu-article">
                  <span className="font-bold">L’Utilisateur </span> : La
                  personne utilisant le Site et les services.
                </p>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Nature des données collectées
            </h3>

            <div className="cgu-section__group">
              <div>
                <p className="cgu-article">
                  <span className="font-bold">
                    Dans le cadre de l’utilisation des Sites, l’Éditeur est
                    susceptible de collecter les catégories de données suivantes
                    concernant ses Utilisateurs :
                  </span>
                </p>

                <p className="cgu-article cgu-article--sub">
                  <div>-Nom</div>
                  <div>-Adresse e-mail</div>
                  <div>-Téléphone</div>
                  <div>-Message</div>
                </p>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Communication des données personnelles à des tiers
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Pas de communication à des tiers
              </h4>
              <div>
                <p className="cgu-article">
                  Vos données ne font l’objet d’aucune communication à des
                  tiers. Vous êtes toutefois informés qu’elles pourront être
                  divulguées en application d’une loi, d’un règlement ou en
                  vertu d’une décision d’une autorité réglementaire ou
                  judiciaire compétente.
                </p>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Information préalable pour la communication des données
              personnelles à des tiers en cas de fusion / absorption
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Collecte de l’opt-in (consentement) préalable à la transmission
                des données suite à une fusion / acquisition
              </h4>
              <div>
                <p className="cgu-article">
                  Dans le cas où nous prendrions part à une opération de fusion,
                  d’acquisition ou à toute autre forme de cession d’actifs, nous
                  nous engageons à obtenir votre consentement préalable à la
                  transmission de vos données personnelles et à maintenir le
                  niveau de confidentialité de vos données personnelles auquel
                  vous avez consenti.
                </p>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Finalité de la réutilisation des données personnelles collectées
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Effectuer les opérations relatives à la gestion des clients
                concernant
              </h4>
              <div>
                <div className="cgu-article">
                  <div>
                    - les contrats ; les commandes ; les livraisons ; les
                    factures ; la comptabilité et en particulier la gestion des
                    comptes clients
                  </div>
                  <div>
                    - un programme de fidélité au sein d’une entité ou plusieurs
                    entités juridiques ;
                  </div>
                  <div>
                    - le suivi de la relation client tel que la réalisation
                    d’enquêtes de satisfaction, la gestion des réclamations et
                    du service après-vente
                  </div>
                  <div>
                    - la sélection de clients pour réaliser des études, sondages
                    et tests produits (sauf consentement des personnes
                    concernées recueilli dans les conditions prévues à l’article
                    6, ces opérations ne doivent pas conduire à l’établissement
                    de profils susceptibles de faire apparaître des données
                    sensibles – origines raciales ou ethniques, opinions
                    philosophiques, politiques, syndicales, religieuses, vie
                    sexuelle ou santé des personnes)
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">Agrégation des données</h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Agrégation avec des données non personnelles
              </h4>
              <div>
                <div className="cgu-article">
                  Nous pouvons publier, divulguer et utiliser les informations
                  agrégées (informations relatives à tous nos Utilisateurs ou à
                  des groupes ou catégories spécifiques d’Utilisateurs que nous
                  combinons de manière à ce qu’un Utilisateur individuel ne
                  puisse plus être identifié ou mentionné) et les informations
                  non personnelles à des fins d’analyse du secteur et du marché,
                  de profilage démographique, à des fins promotionnelles et
                  publicitaires et à d’autres fins commerciales.
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Agrégation avec des données personnelles disponibles sur les
                comptes sociaux de l’Utilisateur
              </h4>
              <div>
                <div className="cgu-article">
                  Si vous connectez votre compte à un compte d’un autre service
                  afin de faire des envois croisés, ledit service pourra nous
                  communiquer vos informations de profil, de connexion, ainsi
                  que toute autre information dont vous avez autorisé la
                  divulgation. Nous pouvons agréger les informations relatives à
                  tous nos autres Utilisateurs, groupes, comptes, aux données
                  personnelles disponibles sur l’Utilisateur.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Collecte des données d’identité
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">Consultation libre</h4>
              <div>
                <div className="cgu-article">
                  La consultation du Site ne nécessite pas d’inscription ni
                  d’identification préalable. Elle peut s’effectuer sans que
                  vous ne communiquiez de données nominatives vous concernant
                  (nom, prénom, adresse, etc). Nous ne procédons à aucun
                  enregistrement de données nominatives pour la simple
                  consultation du Site.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Collecte des données d’identification
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Utilisation de l’identifiant de l’utilisateur uniquement pour
                l’accès aux services
              </h4>
              <div>
                <div className="cgu-article">
                  Nous utilisons vos identifiants électroniques seulement pour
                  et pendant l’exécution du contrat.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Collecte des données du terminal
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Aucune collecte des données techniques
              </h4>
              <div>
                <div className="cgu-article">
                  Nous ne collectons et ne conservons aucune donnée technique de
                  votre appareil (adresse IP, fournisseur d’accès à Internet…).
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">Cookies</h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Durée de conservation des cookies
              </h4>
              <div>
                <div className="cgu-article">
                  Conformément aux recommandations de la CNIL, la durée maximale
                  de conservation des cookies est de 13 mois au maximum après
                  leur premier dépôt dans le terminal de l’Utilisateur, tout
                  comme la durée de la validité du consentement de l’Utilisateur
                  à l’utilisation de ces cookies. La durée de vie des cookies
                  n’est pas prolongée à chaque visite. Le consentement de
                  l’Utilisateur devra donc être renouvelé à l’issue de ce délai.
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">Finalité cookies</h4>
              <div>
                <div className="cgu-article">
                  Les cookies peuvent être utilisés pour des fins statistiques
                  notamment pour optimiser les services rendus à l’Utilisateur,
                  à partir du traitement des informations concernant la
                  fréquence d’accès, la personnalisation des pages ainsi que les
                  opérations réalisées et les informations consultées.
                </div>

                <div className="cgu-article">
                  Vous êtes informé que l’Éditeur est susceptible de déposer des
                  cookies sur votre terminal. Le cookie enregistre des
                  informations relatives à la navigation sur le service (les
                  pages que vous avez consultées, la date et l’heure de la
                  consultation…) que nous pourrons lire lors de vos visites
                  ultérieures.
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Droit de l’Utilisateur de refuser les cookies
              </h4>
              <div>
                <div className="cgu-article">
                  Vous reconnaissez avoir été informé que l’Éditeur peut avoir
                  recours à des cookies. Si vous ne souhaitez pas que des
                  cookies soient utilisés sur votre terminal, la plupart des
                  navigateurs vous permettent de désactiver les cookies en
                  passant par les options de réglage.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Conservation des données techniques
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Durée de conservation des données techniques
              </h4>
              <div>
                <div className="cgu-article">
                  Les données techniques sont conservées pour la durée
                  strictement nécessaire à la réalisation des finalités visées
                  ci-avant.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Délai de conservation des données personnelles et d’anonymisation
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Conservation des données pendant la durée de la relation
                contractuelle
              </h4>
              <div>
                <div className="cgu-article">
                  Conformément à l’article 6-5° de la loi n°78-17 du 6 janvier
                  1978 relative à l’informatique, aux fichiers et aux libertés,
                  les données à caractère personnel faisant l’objet d’un
                  traitement ne sont pas conservées au-delà du temps nécessaire
                  à l’exécution des obligations définies lors de la conclusion
                  du contrat ou de la durée prédéfinie de la relation
                  contractuelle.
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Conservation des données anonymisées au delà de la relation
                contractuelle / après la suppression du compte
              </h4>
              <div>
                <div className="cgu-article">
                  Nous conservons les données personnelles pour la durée
                  strictement nécessaire à la réalisation des finalités décrites
                  dans les présentes CGU. Au-delà de cette durée, elles seront
                  anonymisées et conservées à des fins exclusivement
                  statistiques et ne donneront lieu à aucune exploitation, de
                  quelque nature que ce soit.
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Suppression des données après suppression du compte
              </h4>
              <div>
                <div className="cgu-article">
                  Des moyens de purge de données sont mis en place afin d’en
                  prévoir la suppression effective dès lors que la durée de
                  conservation ou d’archivage nécessaire à l’accomplissement des
                  finalités déterminées ou imposées est atteinte. Conformément à
                  la loi n°78-17 du 6 janvier 1978 relative à l’informatique,
                  aux fichiers et aux libertés, vous disposez par ailleurs d’un
                  droit de suppression sur vos données que vous pouvez exercer à
                  tout moment en prenant contact avec l’Éditeur.
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Suppression des données après 3 ans d’inactivité
              </h4>
              <div>
                <div className="cgu-article">
                  Pour des raisons de sécurité, si vous ne vous êtes pas
                  authentifié sur le Site pendant une période de trois ans, vous
                  recevrez un e-mail vous invitant à vous connecter dans les
                  plus brefs délais, sans quoi vos données seront supprimées de
                  nos bases de données.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">Suppression du compte</h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Suppression du compte à la demande
              </h4>
              <div>
                <div className="cgu-article">
                  L’Utilisateur a la possibilité de supprimer son Compte à tout
                  moment, par simple demande à l’Éditeur OU par le menu de
                  suppression de Compte présent dans les paramètres du Compte le
                  cas échéant.
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Suppression du compte en cas de violation des CGU
              </h4>
              <div>
                <div className="cgu-article">
                  En cas de violation d’une ou de plusieurs dispositions des CGU
                  ou de tout autre document incorporé aux présentes par
                  référence, l’Éditeur se réserve le droit de mettre fin ou
                  restreindre sans aucun avertissement préalable et à sa seule
                  discrétion, votre usage et accès aux services, à votre compte
                  et à tous les Sites.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Indications en cas de faille de sécurité décelée par l’Éditeur
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Information de l’Utilisateur en cas de faille de sécurité
              </h4>
              <div>
                <div className="cgu-article">
                  Nous nous engageons à mettre en oeuvre toutes les mesures
                  techniques et organisationnelles appropriées afin de garantir
                  un niveau de sécurité adapté au regard des risques d’accès
                  accidentels, non autorisés ou illégaux, de divulgation,
                  d’altération, de perte ou encore de destruction des données
                  personnelles vous concernant. Dans l’éventualité où nous
                  prendrions connaissance d’un accès illégal aux données
                  personnelles vous concernant stockées sur nos serveurs ou ceux
                  de nos prestataires, ou d’un accès non autorisé ayant pour
                  conséquence la réalisation des risques identifiés ci-dessus,
                  nous nous engageons à :
                </div>

                <div className="cgu-article cgu-article--sub">
                  <div>
                    - Vous notifier l’incident dans les plus brefs délais ;
                  </div>
                  <div>
                    - Examiner les causes de l’incident et vous en informer ;
                  </div>
                  <div>
                    - Prendre les mesures nécessaires dans la limite du
                    raisonnable afin d’amoindrir les effets négatifs et
                    préjudices pouvant résulter dudit incident
                  </div>
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Limitation de la responsabilité
              </h4>
              <div>
                <div className="cgu-article">
                  En aucun cas les engagements définis au point ci-dessus
                  relatifs à la notification en cas de faille de sécurité ne
                  peuvent être assimilés à une quelconque reconnaissance de
                  faute ou de responsabilité quant à la survenance de l’incident
                  en question.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Transfert des données personnelles à l’étranger
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Pas de transfert en dehors de l’Union européenne
              </h4>
              <div>
                <div className="cgu-article">
                  L’Éditeur s’engage à ne pas transférer les données
                  personnelles de ses Utilisateurs en dehors de l’Union
                  européenne.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Modification des CGU et de la politique de confidentialité
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                En cas de modification des présentes CGU, engagement de ne pas
                baisser le niveau de confidentialité de manière substantielle
                sans l’information préalable des personnes concernées
              </h4>
              <div>
                <div className="cgu-article">
                  Nous nous engageons à vous informer en cas de modification
                  substantielle des présentes CGU, et à ne pas baisser le niveau
                  de confidentialité de vos données de manière substantielle
                  sans vous en informer et obtenir votre consentement.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">
              Droit applicable et modalités de recours
            </h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Application du droit français (législation CNIL) et compétence
                des tribunaux
              </h4>
              <div>
                <div className="cgu-article">
                  Les présentes CGU et votre utilisation du Site sont régies et
                  interprétées conformément aux lois de France, et notamment à
                  la Loi n° 78-17 du 6 janvier 1978 relative à l’informatique,
                  aux fichiers et aux libertés. Le choix de la loi applicable ne
                  porte pas atteinte à vos droits en tant que consommateur
                  conformément à la loi applicable de votre lieu de résidence.
                  Si vous êtes un consommateur, vous et nous acceptons de se
                  soumettre à la compétence non-exclusive des juridictions
                  françaises, ce qui signifie que vous pouvez engager une action
                  relative aux présentes CGU en France ou dans le pays de l’UE
                  dans lequel vous vivez. Si vous êtes un professionnel, toutes
                  les actions à notre encontre doivent être engagées devant une
                  juridiction en France.
                </div>

                <div className="cgu-article">
                  En cas de litige, les parties chercheront une solution amiable
                  avant toute action judiciaire. En cas d’échec de ces
                  tentatives, toutes contestations à la validité,
                  l’interprétation et / ou l’exécution des présentes CGU devront
                  être portées même en cas de pluralité des défendeurs ou
                  d’appel en garantie, devant les tribunaux français.
                </div>
              </div>
            </div>
          </div>

          <div className="cgu-section">
            <h3 className="cgu-section__title">Portabilité des données</h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                Portabilité des données
              </h4>
              <div>
                <div className="cgu-article">
                  L’Éditeur s’engage à vous offrir la possibilité de vous faire
                  restituer l’ensemble des données vous concernant sur simple
                  demande. L’Utilisateur se voit ainsi garantir une meilleure
                  maîtrise de ses données, et garde la possibilité de les
                  réutiliser. Ces données devront être fournies dans un format
                  ouvert et aisément réutilisable.
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Privacy;
