import { Button, Modal, Spin } from "antd";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";

const ProductsModal = ({ isModalOpen, setOpenModal, refresh }) => {
  const [isLoading, setLoading] = useState(false);

  const today = new Date();

  const clientSchema = Yup.object().shape({
    name: Yup.string().min(3, "Too Short").required("Required"),
  });

  const createProduct = async (values) => {
    setLoading(true);
    await addDoc(collection(db, "products"), { ...values, addedBy: "admin" })
      .then(async () => {
        refresh();
        toast.success("Product créé");
        setOpenModal();
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Ajouter Product"
      open={isModalOpen}
      onCancel={() => {
        setOpenModal();
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="Annuler"
    >
      <div className="input-section" style={{ width: "100%" }}>
        <Formik
          validationSchema={clientSchema}
          initialValues={{
            name: "",
            transportType: 1,
          }}
          onSubmit={async (values, { resetForm }) => {
            await createProduct(values);
            resetForm();
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue }) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label className="form-label">PRODUCT NAME</label>
              {errors.name && touched.name ? (
                <div style={{ color: "red" }}>{errors.name}</div>
              ) : null}
              <Field
                type="text"
                name="name"
                placeholder="Product"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
              />

              <label className="form-label">NATURE</label>
              <select
                className="form-input"
                component="select"
                placeholder="Select service"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
                onChange={(e) =>
                  setFieldValue("transportType", parseInt(e.target.value))
                }
              >
                <option value={1}>Transport Sanguin</option>
                <option value={2}>Autre Transport</option>
              </select>

              <Button
                type="primary"
                style={{
                  borderRadius: 10,
                  backgroundColor: "red",
                  display: "flex",
                  height: 48,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleSubmit}
              >
                Create
              </Button>
            </div>
          )}
        </Formik>
      </div>

      {isLoading && (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            height: "5rem",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </Modal>
  );
};

export default ProductsModal;
