import React, { Component, useEffect, useState } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";
import Metadata from "../../components/Metadata";
import action_dots_icon from "../../assets/images/action_dots_icon.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";
import {
  collection,
  getDocs,
  orderBy,
  query,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db, auth, functions } from "../../config/firebase";
import { httpsCallable } from "firebase/functions";

import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Button, Spin, Modal } from "antd";
import {
  UserOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LivreursModal from "../../components/modals/LivreursModal";
import close from "../../assets/images/closee.svg";
import Axios from "axios";

const Drivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [isLoadingDelete, setLoadingDelete] = useState(false);

  const [isOpenCreateModal, setOpenCreateModal] = useState(false);

  const deleteDriverByEmail = httpsCallable(functions, "deleteUserByUidWeb");

  const getDrivers = async () => {
    setLoading(true);
    setDrivers([]);
    await getDocs(query(collection(db, "drivers"), orderBy("firstName", "asc")))
      .then((drivers) => {
        drivers.forEach((doc) => {
          setDrivers((prev) => [...prev, { ...doc.data(), uid: doc.id }]);
        });
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => setLoading(false));
  };

  const deleteDriver = async (uid, setIsModalOpen, isModalOpen) => {
    setLoadingDelete(true);

    await deleteDriverByEmail({
      uid: `${uid}`,
    })
      .then(() => {
        const docRef = doc(db, "drivers", uid);

        deleteDoc(docRef)
          .then(() => {
            toast.success("Driver successfully deleted!");
            getDrivers();
            setIsModalOpen(!isModalOpen);
          })
          .catch((error) => {
            console.error("Error deleting document: ", error);
            toast.error("Server error");
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Server error");
      })

      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const filterDriverName = (search) => {
    setFilteredDrivers(
      drivers.filter((driver) =>
        driver.firstName.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  useEffect(() => {
    getDrivers();
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />
      <LivreursModal
        isModalOpen={isOpenCreateModal}
        setOpenModal={() => setOpenCreateModal(!isOpenCreateModal)}
        refresh={() => getDrivers()}
      />
      <ToastContainer />
      <div className="grid grid--dashboard">
        <Menu />
        <div className="container container--dashboard">
          <div className="container dashboard-header">
            <h2 style={{ width: "50%" }} className="dashboard-title">
              Livreurs
            </h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                block
                icon={<PlusOutlined />}
                style={{
                  background: "#FF4E4E",
                  color: "white",
                  width: "200px",
                  height: "100%",
                }}
                onClick={() => setOpenCreateModal(!isOpenCreateModal)}
              >
                Livreurs
              </Button>
            </div>
          </div>

          <div className="archive-toolbar flex-double">
            <div className="searchbar-box">
              <input
                type="text"
                placeholder="Search by name"
                onChange={(e) => filterDriverName(e.target.value)}
              />
              <img
                src={search_icon}
                alt="Search Bar Icon"
                title="Search Bar Icon"
                className="search-icon"
              />
            </div>
            <div className="tools-one">
              <div className="tools-grp">
                <img
                  src={filter_icon}
                  alt="Filter Icon"
                  title="Filter Icon"
                  className="filter-icon"
                />
              </div>
            </div>
          </div>

          <div className="history-entries __header">
            <div className="history-entry">
              <div className="history-code"></div>
              <div className="profile">Nom</div>
              <div>Status</div>
              <div>Derniére connexion</div>
              <div>Email</div>
              <div></div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                marginTop: "10rem",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                height: "10rem",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div className="history-list">
              <ul className="history-entries">
                {(filteredDrivers.length > 0 ? filteredDrivers : drivers).map(
                  (driver, index) => {
                    return (
                      <DriverItem
                        driver={driver}
                        deleteDriver={deleteDriver}
                        isLoadingDelete={isLoadingDelete}
                        key={index}
                      />
                    );
                  }
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function DriverItem({ driver, deleteDriver, isLoadingDelete }) {
  const [isMenu, setIsMenu] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <li className="history-entry">
      <Modal
        title="Supprimer"
        open={isModalOpen}
        onOk={() => deleteDriver(driver.uid, setIsModalOpen, isModalOpen)}
        onCancel={handleCancel}
        okText="Supprimer"
        cancelText="Cancel"
      >
        <p>Êtes-vous sûr de vouloir supprimer cet Livreur ?</p>
        {isLoadingDelete && (
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "5rem",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Modal>
      <div className="history-code">{driver.firstName.substring(0, 1)}</div>
      <div className="profile">
        <div className="avatar">
          {driver.profile_picture_url ? (
            <img src={driver.profile_picture_url} alt="Avatar" title="Avatar" />
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <UserOutlined />
            </div>
          )}
        </div>
        {driver.firstName + " " + driver.lastName}
      </div>

      {driver.activity ? (
        <>
          <div
            className={
              driver.activity?.type === "login"
                ? "status online"
                : "status offline"
            }
          >
            {driver.activity?.type}
          </div>
        </>
      ) : (
        <img src={close} alt="close Icon" style={{ marginTop: 10 }} />
      )}

      {driver.activity ? (
        <div className="items">
          {moment(driver.activity?.time.toDate()).format("DD/MM/YYYY")}
        </div>
      ) : (
        <img src={close} alt="close Icon" style={{ marginTop: 10 }} />
      )}

      <div className="items">{driver.email}</div>

      <button className="items dotsButton" onClick={() => setIsMenu(!isMenu)}>
        {isMenu && (
          <div
            style={{
              position: "absolute",
              background: "white",
              marginBottom: 110,
              borderColor: "gray",
            }}
          >
            <Button
              size="large"
              block
              style={{ background: "rgb(83, 190, 206)", color: "white" }}
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`${driver.uid}`, {
                  state: { user: driver, type: "drivers" },
                });
                setIsMenu(!isMenu);
              }}
            >
              View Profile
            </Button>
            <Button
              size="large"
              block
              icon={<DeleteOutlined />}
              style={{ background: "rgb(220, 124, 124)", color: "white" }}
              onClick={() => {
                setIsMenu(!isMenu);
                setIsModalOpen(true);
              }}
            >
              Delete Livreur
            </Button>
          </div>
        )}
        <img src={action_dots_icon} alt="Action" title="Action" />
      </button>
    </li>
  );
}

export default Drivers;
