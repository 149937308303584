import React, { useState, useEffect } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import search_icon from "../../assets/images/search_icon.svg";
import action_dots_icon from "../../assets/images/action_dots_icon.svg";

import Profile from "../../assets/svg/profile.svg";
import Tools from "../../assets/svg/tools.svg";
import Time from "../../assets/svg/time.svg";
import { useLocation, Link } from "react-router-dom";
import { Field, Formik } from "formik";
import { db } from "../../config/firebase";
import { ToastContainer, toast } from "react-toastify";
import {
  arrayUnion,
  doc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Button, Spin } from "antd";
import * as Yup from "yup";
import {
  DeleteOutlined,
  HomeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

const EtablishmentProfile = () => {
  const { state } = useLocation();
  const { establishment } = state;
  const [isModifying, setModifying] = useState(false);
  const [loader, setLoader] = useState();
  const [ticket, setTicket] = useState([]);
  const [filteredTicket, setFilteredTicket] = useState([]);
  const [form, setForm] = useState("default");
  const [isLoading, setLoading] = useState(false);
  const [services, setServices] = useState(
    establishment?.services ? establishment.services : []
  );
  var buttonText = isModifying ? "Enregistrer Modification" : "Modifier";

  const clientSchema = Yup.object().shape({
    referentName: Yup.string().min(5, "Too Short").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    //telephone: Yup.number().required("Required"),
    //mobileNumber: Yup.number().required("Required"),
  });

  const getTickets = async (id) => {
    try {
      setLoader(true);
      const arrayResult = [];
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const querySnapshot = await getDocs(
        query(collection(db, "tickets"), where("client.uid", "==", id))
      );

      querySnapshot.forEach((doc) => {
        var { createdAt } = doc.data();
        if (createdAt.toDate() >= date) {
          arrayResult.push({ ...doc.data(), uid: doc.id });
        }
      });

      arrayResult.sort((a, b) => b.ticketNo - a.ticketNo);
      setTicket(arrayResult);
    } catch (error) {
      toast.error(error.message);
      console.log("===== Error from catch =======");
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const updateClient = async (dataEdit) => {
    const docUpdate = doc(db, "clients", establishment.uid);
    await updateDoc(docUpdate, dataEdit)
      .then(() => console.log("Updated"))
      .catch((err) => {
        toast.error("Error");
        console.log(err.message);
      });
  };

  const handleModify = async (values) => {
    if (!isModifying) return setModifying(true);
    try {
      setLoading(true);
      await updateClient(values);
      setModifying(false);
      toast.success("Establishment updated!");
    } catch (error) {
      console.log(error);
      toast.error("Error");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitService = async ({ service }) => {
    try {
      let newService = { name: service, enable: true };
      setLoading(true);
      const updateRef = doc(db, "clients", establishment.uid);
      // Atomically add a new region to the "services" array field.
      await updateDoc(updateRef, {
        services: arrayUnion(newService),
      }).then(() => setServices((prev) => [...prev, newService]));
      toast.success("Establishment updated!");
    } catch (error) {
      console.log(error);
      toast.error("Error");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveService = async (index) => {
    try {
      let newArray = services;
      setLoading(true);
      newArray.splice(index, 1);
      await updateClient({ services: newArray }).then(() =>
        setServices(newArray)
      );
      toast.success("Establishment updated!");
    } catch (error) {
      console.log(error);
      toast.error("Error");
    } finally {
      setLoading(false);
    }
  };

  const filterTicket = (search) => {
    const searchString = search.toString();
    setFilteredTicket(
      ticket.filter((item) => item.ticketNo.toString().includes(searchString))
    );
  };

  useEffect(() => {
    getTickets(establishment.uid);
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />
      <ToastContainer />
      <div className="grid grid--dashboard">
        <Menu />
        <div className="container container--dashboard">
          <div className="flex-double no-center">
            <div className="flex-double__l w-35">
              <div className="archive-hierarchy">
                <div
                  className="archive-hierarchy__item pointer"
                  onClick={() => setForm("default")}
                >
                  <div className="label-group">
                    <img src={Profile} alt="Folder icon" title="Folder icon" />
                    Profile
                  </div>
                </div>

                <div
                  className="archive-hierarchy__item pointer"
                  onClick={() => setForm("services")}
                >
                  <div className="label-group">
                    <img src={Tools} alt="Folder icon" title="Folder icon" />
                    Gestion des services
                  </div>
                </div>

                <div
                  className="archive-hierarchy__item pointer"
                  onClick={() => setForm("history")}
                >
                  <div className="label-group">
                    <img src={Time} alt="Folder icon" title="Folder icon" />
                    Historique
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-double__r w-65">
              {form === "default" && (
                <form className="form form-settings">
                  <Formik
                    validationSchema={clientSchema}
                    initialValues={{
                      referentName: establishment?.referentName
                        ? establishment.referentName
                        : "",
                      zone: establishment?.zone ? establishment.zone : "",
                      email: establishment?.email ? establishment.email : "",
                      telephone: establishment?.telephone
                        ? establishment.telephone
                        : "",
                      mobileNumber: establishment?.mobileNumber
                        ? establishment.mobileNumber
                        : "",
                      // Adresses values
                      addressLine1: establishment?.facturationAddress
                        ?.addressLine1?.address
                        ? establishment?.facturationAddress?.addressLine1
                            ?.address
                        : "",
                      facturationAddress: establishment?.facturationAddress
                        ? establishment?.facturationAddress
                        : "",
                    }}
                    onSubmit={(values) => handleModify(values)}
                  >
                    {({
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      values,
                      errors,
                    }) => (
                      <div className="form-settings__section">
                        <div className="form-title">
                          Informations du etablishment
                        </div>

                        <div
                          style={{
                            marginBottom: 30,
                            width: "100%",
                            paddingLeft: 10,
                          }}
                        >
                          <div
                            style={{
                              color: "#3c3c3c",
                              marginBottom: 40,
                              fontSize: "17px",
                              fontWeight: 500,
                            }}
                          >
                            <label>LOGO</label>
                          </div>

                          {establishment?.profile_picture_url ? (
                            <img
                              width={150}
                              alt="profilePicture"
                              style={{
                                borderRadius: 100,
                              }}
                              src={establishment?.profile_picture_url}
                            />
                          ) : (
                            <div
                              style={{
                                marginBottom: 30,
                                width: "100%",
                                cursor: "pointer",
                              }}
                            >
                              <PlusCircleOutlined
                                style={{ fontSize: "50px" }}
                              />
                            </div>
                          )}
                        </div>

                        <div className="input-section">
                          <div className="input-group">
                            <label className="form-label">
                              NOM ET PRÉNOM DU RÉFÉRENT
                            </label>
                            <Field
                              type="text"
                              name="referentName"
                              placeholder="Nom"
                              className="form-input"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              disabled={!isModifying}
                            />
                          </div>

                          <div className="input-group">
                            <label className="form-label">ZONE</label>
                            <Field
                              name="zone"
                              className="form-input"
                              component="select"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              disabled={!isModifying}
                            >
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Martinique">Martinique</option>
                            </Field>
                          </div>
                        </div>

                        <div className="input-section">
                          <div className="input-group">
                            <label className="form-label">EMAIL</label>
                            <Field
                              type="text"
                              placeholder="Prenom"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              name="email"
                              className="form-input"
                              disabled={!isModifying}
                            />
                          </div>

                          <div className="input-group">
                            <label className="form-label">TELEPHONE</label>
                            <Field
                              type="number"
                              placeholder="No telephone"
                              name="telephone"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              className="form-input"
                              disabled={!isModifying}
                            />
                          </div>
                        </div>

                        <div className="input-section">
                          <div className="input-group">
                            <label className="form-label">MOBILE</label>
                            <Field
                              type="number"
                              placeholder="No mobile number"
                              className="form-input"
                              name="mobileNumber"
                              style={
                                !isModifying
                                  ? { backgroundColor: "#CFCFCF" }
                                  : {}
                              }
                              disabled={!isModifying}
                            />
                          </div>
                        </div>
                        {/* ---------------------------------- ADDRESSS -------------------------------------------------------------- */}
                        <div className="form-settings__section">
                          <div className="form-title">
                            Adressse de facturation
                          </div>

                          <div className="input-section __full">
                            <div className="input-group">
                              <label className="form-label">
                                Address line 1
                              </label>
                              <Field
                                type="text"
                                name="facturationAddress.addressLine1.address"
                                placeholder="Address line 1"
                                className="form-input"
                                style={
                                  !isModifying
                                    ? { backgroundColor: "#CFCFCF" }
                                    : {}
                                }
                                disabled={!isModifying}
                              />
                            </div>
                          </div>

                          <div className="input-section __full">
                            <div className="input-group">
                              <label className="form-label">
                                Address line 2
                              </label>
                              <Field
                                type="text"
                                name="facturationAddress.addressLine2"
                                placeholder="Address line 2"
                                className="form-input"
                                style={
                                  !isModifying
                                    ? { backgroundColor: "#CFCFCF" }
                                    : {}
                                }
                                disabled={!isModifying}
                              />
                            </div>
                          </div>

                          <div className="input-section __full">
                            <div className="input-group">
                              <label className="form-label">
                                Address line 3
                              </label>
                              <Field
                                type="text"
                                name="facturationAddress.addressLine3"
                                placeholder="Address line 3"
                                className="form-input"
                                style={
                                  !isModifying
                                    ? { backgroundColor: "#CFCFCF" }
                                    : {}
                                }
                                disabled={!isModifying}
                              />
                            </div>
                          </div>
                          <div className="input-section">
                            <div className="input-group">
                              <label className="form-label">Ville</label>
                              <Field
                                type="text"
                                name="facturationAddress.ville"
                                placeholder="Ville"
                                className="form-input"
                                style={
                                  !isModifying
                                    ? { backgroundColor: "#CFCFCF" }
                                    : {}
                                }
                                disabled={!isModifying}
                              />
                            </div>

                            <div className="input-group">
                              <label className="form-label">Departement</label>
                              <Field
                                type="text"
                                name="facturationAddress.department"
                                placeholder="Departement"
                                className="form-input"
                                style={
                                  !isModifying
                                    ? { backgroundColor: "#CFCFCF" }
                                    : {}
                                }
                                disabled={!isModifying}
                              />
                            </div>
                          </div>

                          <div className="input-section">
                            <div className="input-group">
                              <label className="form-label">Code Postal</label>
                              <Field
                                type="text"
                                name="facturationAddress.postalCode"
                                placeholder="Code Postal"
                                className="form-input"
                                style={
                                  !isModifying
                                    ? { backgroundColor: "#CFCFCF" }
                                    : {}
                                }
                                disabled={!isModifying}
                              />
                            </div>

                            <div className="input-group">
                              <label className="form-label">Pays</label>
                              <Field
                                type="text"
                                name="facturationAddress.pays"
                                placeholder="Pays"
                                className="form-input"
                                style={
                                  !isModifying
                                    ? { backgroundColor: "#CFCFCF" }
                                    : {}
                                }
                                disabled={!isModifying}
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          type="primary"
                          style={{
                            borderRadius: 10,
                            backgroundColor: "#FF4E4E",
                            borderColor: "#FF4E4E",
                            width: "100%",
                            height: "48px",
                            //   fontFamily: "SegoeUI",
                            fontSize: "20px",
                          }}
                          disabled={isLoading}
                          onClick={handleSubmit}
                        >
                          {buttonText}
                        </Button>
                      </div>
                    )}
                  </Formik>
                  {isLoading && (
                    <div
                      style={{
                        marginTop: "2rem",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        height: "4rem",
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  )}
                </form>
              )}
              {form === "services" && (
                <form className="form form-settings form-etablishement-profile">
                  <div className="form">
                    <div className="form_header">
                      <div className="form_profile">
                        {establishment?.profile_picture_url ? (
                          <img
                            width={150}
                            alt="profilePicture"
                            style={{
                              borderRadius: 100,
                            }}
                            src={establishment?.profile_picture_url}
                          />
                        ) : (
                          <HomeOutlined style={{ fontSize: "50px" }} />
                        )}

                        <div>
                          <span style={{ paddingLeft: 20 }} className="__name">
                            {establishment?.enterprise}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-settings__section">
                    <div className="form-title">Gestion des services</div>

                    {services.length > 0 ? (
                      services.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="input-group-check"
                            style={{ color: "#3c3c3c" }}
                          >
                            <div className="input-group">
                              <label className="form-label">{item.name}</label>
                              <Button
                                onClick={() => handleRemoveService(i)}
                                danger
                              >
                                <DeleteOutlined />
                              </Button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>No services</div>
                    )}
                  </div>

                  <div style={{ paddingTop: "50px" }}>
                    <div className="form-title">Ajouter un service</div>
                    <div className="input-section" style={{ width: "100%" }}>
                      <Formik
                        initialValues={{
                          service: "",
                        }}
                        onSubmit={(values) => handleSubmitService(values)}
                      >
                        {({ handleSubmit, values, errors }) => (
                          <div style={{ width: "100%" }}>
                            <label className="form-label">
                              Créer un nouveau service
                            </label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                <Field
                                  type="text"
                                  name="service"
                                  disabled={isLoading}
                                  placeholder="Service"
                                  className="form-input form-input-br-btm"
                                />
                              </div>

                              <Button
                                type="primary"
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: "red",
                                  display: "flex",
                                  height: 48,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: 20,
                                }}
                                disabled={isLoading}
                                onClick={handleSubmit}
                              >
                                <PlusCircleOutlined
                                  style={{ fontSize: "20px" }}
                                />
                              </Button>
                            </div>
                          </div>
                        )}
                      </Formik>
                      {isLoading && (
                        <div
                          style={{
                            marginTop: "2rem",
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "4rem",
                          }}
                        >
                          <Spin size="large" />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              )}

              {loader === true ? (
                <div
                  style={{
                    marginTop: "10rem",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    height: "10rem",
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  {form === "history" && (
                    <form className="form form-settings form-etablishement-profile">
                      <div className="archive-toolbar flex-double">
                        <div className="searchbar-box">
                          <input
                            type="text"
                            placeholder="Ticket Nº"
                            onChange={(e) => filterTicket(e.target.value)}
                          />
                          <img
                            src={search_icon}
                            alt="Search Bar Icon"
                            title="Search Bar Icon"
                            className="search-icon"
                          />
                        </div>
                      </div>

                      <div className="history-entries __header">
                        <div className="history-entry3">
                          <div>Ticket Nº</div>
                          <div>Status</div>
                          <div>Date</div>
                          <div>Driver</div>
                          <div>Delivered</div>
                          <div>View</div>
                        </div>
                      </div>

                      <div className="history-entries">
                        {(filteredTicket.length > 0
                          ? filteredTicket
                          : ticket
                        ).map((item, index) => (
                          <div className="history-entry4">
                            <div className="">{item?.ticketNo}</div>
                            <div className="status status--2 ">
                              <div
                                className={
                                  item?.status === "completed"
                                    ? "label-bkg complete"
                                    : item?.status === "initiated"
                                    ? "label-bkg hold"
                                    : "label-bkg active"
                                }
                              >
                                {item?.status === "completed"
                                  ? "Completed"
                                  : item?.status === "initiated"
                                  ? "En Attente"
                                  : "On Cours"}
                              </div>
                            </div>
                            <div className="last-connexion">
                              {moment(item?.createdAt.toDate()).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                            <div className="login">
                              {item?.driver?.name === undefined
                                ? "En Attente"
                                : item?.driver?.name}
                            </div>
                            <div className="logout">
                              {item.completedAt === undefined
                                ? "En Attente"
                                : moment(item?.completedAt.toDate()).format(
                                    "DD/MM/YYYY"
                                  )}
                            </div>

                            <ClientItem
                              id={item.uid}
                              idDriver={item?.driver?.id}
                              data={item}
                              state1={moment(item?.createdAt?.toDate())
                                .add(15, "m")
                                .format("HH:mm A")}
                              state2={moment(item?.createdAt?.toDate())
                                .add(30, "m")
                                .format("HH:mm A")}
                            />
                          </div>
                        ))}
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function ClientItem({ id, idDriver, data, state1, state2 }) {
  return (
    <Link to={"/admin/tracking"} state={{ id, idDriver, data, state1, state2 }}>
      <img src={action_dots_icon} alt="Action" title="Action" />
    </Link>
  );
}

export default EtablishmentProfile;
