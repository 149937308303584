import React from "react";

const CardProgressBar = ({ data }) => {
  return (
    <div>
      {/* <div className="card--medical__bd">
        <div className="flex-double">
          <div className="flex-double__l">
            <div>
              <span className="card--medical__text-tip card--medical__text-tip__big card--medical__text-tip__big-w">
                Hospital B Adresse de Livraison 1234
              </span>
            </div>
          </div>

          <div className="flex-double__r __r">
            <div>
              <span className="card--medical__text-tip card--medical__text-tip__big card--medical__text-tip__big-w">
                Hospital B Adresse de Livraison 1234
              </span>
            </div>
          </div>
        </div>
      </div> */}

      {/* //test// */}

      <div className="card--medical__bd" style={{ paddingTop: 20 }}>
        <div className="progress-bar">
          <div className="progress-bar__container">
            <div className="progress-bar__icon completed"></div>

            <div className="progress-bar__progress ">
              <div
                className={
                  data?.depotDate
                    ? "progress-bar__progress__inner100"
                    : data?.receptionDate
                    ? "progress-bar__progress__inner"
                    : ""
                }
              ></div>
            </div>

            <div
              className={data?.depotDate ? "progress-bar__icon completed" : ""}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProgressBar;
