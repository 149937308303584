import React, { useEffect, useState } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";
import Metadata from "../../components/Metadata";
import MedicalCard from "../../components/dashboard/MedicalCard";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";
import { Spin } from "antd";

const Dashboard = () => {
  const [tickets, setDataTickets] = useState([]);
  const [ticketsAttente, setDataTicketsAttente] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getTickets = async () => {
    try {
      setLoading(true);
      const arrayResult = [];
      const arrayResultAttente = [];
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const querySnapshot = await getDocs(
        query(collection(db, "tickets"), where("status", "==", "inProgress"))
      );
      const querySnapshotInitiated = await getDocs(
        query(collection(db, "tickets"), where("status", "==", "initiated"))
      ).catch((error) => console.log(error));
      querySnapshot.forEach((doc) => {
        var { createdAt } = doc.data();
        if (createdAt?.toDate() >= date) {
          arrayResult.push({ ...doc.data(), uid: doc.id });
        }
      });
      querySnapshotInitiated.forEach((doc) => {
        var { createdAt } = doc.data();
        if (createdAt?.toDate() >= date) {
          arrayResultAttente.push({ ...doc.data(), uid: doc.id });
        }
      });
      arrayResult.sort((a, b) => b.ticketNo - a.ticketNo);
      setDataTickets(arrayResult);
      arrayResultAttente.sort((a, b) => b.ticketNo - a.ticketNo);
      setDataTicketsAttente(arrayResultAttente);
    } catch (error) {
      toast.error(error.message);
      console.log("===== Error from catch =======");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard" />
      <Header />

      <div className="grid grid--dashboard">
        <Menu />
        {isLoading ? (
          <div
            style={{
              marginTop: "10rem",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "10rem",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div className="container container--dashboard">
            <h2 className="dashboard-title" style={{ marginBottom: 30 }}>
              Dashboard
            </h2>
            <div className="grid grid--gap2">
              <div>
                <h2 className="dashboard-title dashboard-title__sub-1">
                  Active
                </h2>
                {tickets.map((ticket, key) => {
                  return (
                    <MedicalCard
                      key={key}
                      {...ticket}
                      data={ticket}
                      type={"On course"}
                    />
                  );
                })}
              </div>

              <div>
                <h2 className="dashboard-title dashboard-title__sub-1">
                  En Attente
                </h2>
                {ticketsAttente.map((ticket) => {
                  return (
                    <MedicalCard
                      {...ticket}
                      data={ticket}
                      type={"En Attente"}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
