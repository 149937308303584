import React, { Component } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import action_dots_icon from "../../assets/images/action_dots_icon.svg";
import arrow_down_22_icon from "../../assets/images/arrow_down_22_icon.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";
import avatar_2 from "../../assets/images/avatar_2.png";

class CreateProduct extends Component {
  render() {
    return (
      <div className="wrapper wrapper--light">
        <Metadata title="Dashboard - Tracking" />
        <Header />

        <div className="grid grid--dashboard">
          <Menu />

          <div className="container container--dashboard">
            
            <h2 className="dashboard-title" style={{ textAlign: 'center' }}>Créer un produit</h2>

              <form className="form form-reminder">

                  <div className="form-title __middle">Type de transport</div>

                  <div className="form-settings__section">
                  
                      <div className="input-section __middle">
                          <div className="square"></div>
                          <div className="square"></div>
                      </div>
                      
                      <div className="input-section __full">
                          <div className="input-group">
                              <label className="form-label">Nature</label>
                              <input type="text" placeholder="Sélectionner nature" className="form-input form-input-br-btm" />
                          </div>
                      </div>

                      <div className="input-section __full">
                          <div className="input-group">
                              <label className="form-label">New nature / service</label>
                              <input type="text" placeholder="Sélectionner nature" className="form-input" />
                          </div>
                      </div>

                      <div className="input-section __full">
                          <div className="input-group">
                              <label className="form-label">Description</label>
                              <textarea className="form-input textarea"></textarea>
                          </div>
                      </div>
                  </div>
              </form>
            </div>
        </div>

      </div>
    );
  }
}

export default CreateProduct;
