import React from 'react';
import { Helmet } from "react-helmet";

const Metadata = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>MEDIK'DOM {props.title ? `- ${props.title}` : ''}</title>
      <meta name="description" content="Medik'dom est le Spécialiste du transport Médical en Guadeloupe" />
      <meta name="keywords" content="Guadeloupe, transport médical, spécialiste transport médical, transport médical guadeloupe, santé guadeloupe, caraibes, antilles" />
      <link rel="canonical" href="http://mysite.com/example" />
      <meta property="og:title" content="Medikdom" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.medik-dom.web.app/" />
      <meta property="og:image" content={require('../assets/images/mdkd_logo.svg')} />
    </Helmet>
  )
}

export default Metadata;