import React, { useEffect, useState } from "react";

import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import profile from "../../assets/images/profile-icon.svg";
import arrow_down_22_icon from "../../assets/images/arrow_down_22_icon.svg";
import closee from "../../assets/images/closee.svg";
import search_icon from "../../assets/images/search_icon.svg";
import logout_icon from "../../assets/images/logout_icon.svg";

import { db } from "../../config/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const History = () => {
  const [driver, setDriver] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredDriver, setFilteredDriver] = useState([]);

  const navigate = useNavigate();

  const getDrivers = async () => {
    setLoading(true);
    setDriver([]);
    await getDocs(query(collection(db, "drivers"), orderBy("firstName", "asc")))
      .then((products) => {
        products.forEach((doc) => {
          setDriver((prev) => [...prev, { ...doc.data(), uid: doc.id }]);
        });
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => setLoading(false));
  };

  const filterDriver = (search) => {
    setFilteredDriver(
      driver.filter((item) =>
        item.firstName.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const activeTicket = async (id) => {
    try {
      const docRef = doc(db, "tickets", id);
      const docSnap = await getDoc(docRef);
      var uid = docSnap.id;

      navigateTicket(docSnap.data(), uid);
    } catch (e) {
      console.error(e);
    }
  };

  const navigateTicket = (item, uid) => {
    const id = uid;
    const idDriver = item?.driver?.id;
    const data = item;
    const state1 = moment(item?.createdAt?.toDate())
      .add(15, "m")
      .format("HH:mm A");
    const state2 = moment(item?.createdAt?.toDate())
      .add(30, "m")
      .format("HH:mm A");

    navigate(`/admin/tracking`, {
      state: { id, idDriver, data, state1, state2 },
    });
  };

  useEffect(() => {
    getDrivers();
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />
      <ToastContainer />
      <div className="grid grid--dashboard">
        <Menu />
        <div className="container container--dashboard">
          <h2 className="dashboard-title">Historique de connexion</h2>

          <div className="archive-toolbar flex-double">
            <div className="searchbar-box">
              <input
                type="text"
                placeholder="Search by name"
                onChange={(e) => filterDriver(e.target.value)}
              />
              <img
                src={search_icon}
                alt="Search Bar Icon"
                title="Search Bar Icon"
                className="search-icon"
              />
            </div>
          </div>

          <div className="history-entries __header">
            <div className="history-entry5">
              <div className="profile">
                Name
                <img
                  src={arrow_down_22_icon}
                  alt="Arrow down icon"
                  title="Arrow down icon"
                  style={{ transform: "rotate(90deg)", marginLeft: "10px" }}
                />
              </div>
              <div>Status</div>
              <div>Last Conection</div>
              <div>Active Ticket</div>
            </div>
          </div>

          {isLoading ? (
            <div
              style={{
                marginTop: "10rem",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                height: "10rem",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div className="history-entries">
                {(filteredDriver.length > 0 ? filteredDriver : driver).map(
                  (item, index) => (
                    <>
                      <div className="history-entry5">
                        <div className="profile">
                          <div className="avatar">
                            <img
                              src={
                                item.profile_picture_url === null
                                  ? profile
                                  : item.profile_picture_url
                              }
                              alt="test"
                              title="test"
                            />
                          </div>
                          <div style={{ display: "flex" }}>
                            <div>{item.firstName}</div>
                            <div>{item.lastName}</div>
                          </div>
                        </div>
                        <div
                          className={
                            item?.activity?.type === "login"
                              ? "status online "
                              : " status offline"
                          }
                        >
                          {item?.activity?.type === "login"
                            ? "En ligne "
                            : " Déconnecté"}
                        </div>
                        <div style={{ paddingTop: 10 }}>
                          {moment(item?.activity?.time.toDate()).format(
                            "DD/MM/YYYY HH:mm A"
                          )}
                        </div>

                        {item.activeTicket !== "" ? (
                          <img
                            style={{ paddingLeft: 50, paddingTop: 10 }}
                            src={logout_icon}
                            alt="ticketIcon"
                            className="hover"
                            onClick={() => activeTicket(item?.activeTicket)}
                          />
                        ) : (
                          <img
                            style={{ paddingLeft: 50, paddingTop: 10 }}
                            src={closee}
                            alt="closeIcon"
                            title="closeIcon"
                          />
                        )}
                      </div>
                    </>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;
