import React, { useEffect, useState } from "react";

import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import action_dots_icon from "../../assets/images/action_dots_icon.svg";
import arrow_down_22_icon from "../../assets/images/arrow_down_22_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";
import { toast, ToastContainer } from "react-toastify";
import { db } from "../../config/firebase";
import {
  collection,
  deleteDoc,
  addDoc,
  doc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { Button, Modal, Spin } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ProductsModal from "../../components/modals/ProductsModal";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [isOpenCreateModal, setOpenCreateModal] = useState(false);

  const getProducts = async () => {
    setLoading(true);
    setProducts([]);
    await getDocs(query(collection(db, "products"), orderBy("name", "asc")))
      .then((products) => {
        products.forEach((doc) => {
          setProducts((prev) => [...prev, { ...doc.data(), uid: doc.id }]);
        });
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => setLoading(false));
  };

  const deleteProduct = async (uid) => {
    setLoadingDelete(true);
    await deleteDoc(doc(db, "products", uid))
      .then(async () => {
        toast.success("Product deleted");
        await getProducts();
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  };

  const filterProducts = (search) => {
    setFilteredProducts(
      products.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="wrapper wrapper--light">
      <Metadata title="Dashboard - Tracking" />
      <Header />
      <ProductsModal
        isModalOpen={isOpenCreateModal}
        setOpenModal={() => setOpenCreateModal(!isOpenCreateModal)}
        refresh={() => getProducts()}
      />
      <ToastContainer />
      <div className="grid grid--dashboard">
        <Menu />
        <div className="container container--dashboard">
          <div className="container dashboard-header">
            <h2 style={{ width: "50%" }} className="dashboard-title">
              Products
            </h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                block
                icon={<PlusOutlined />}
                style={{
                  background: "#FF4E4E",
                  color: "white",
                  width: "200px",
                  height: "100%",
                }}
                onClick={() => setOpenCreateModal(!isOpenCreateModal)}
              >
                Products
              </Button>
            </div>
          </div>

          <div className="archive-toolbar flex-double">
            <div className="searchbar-box">
              <input
                type="text"
                placeholder="Search by name"
                onChange={(e) => filterProducts(e.target.value)}
              />
              <img
                src={search_icon}
                alt="Search Bar Icon"
                title="Search Bar Icon"
                className="search-icon"
              />
            </div>
          </div>

          <div className="history-entries __header">
            <div className="history-entry2">
              <div className="item1">
                Name
                <img
                  src={arrow_down_22_icon}
                  alt="Arrow down icon"
                  title="Arrow down icon"
                  style={{ transform: "rotate(90deg)", marginLeft: "10px" }}
                />
              </div>
              <div className="item2">Nature</div>
              <div className="item3">Created by</div>
              <div></div>
            </div>
          </div>

          {isLoading ? (
            <div
              style={{
                marginTop: "10rem",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                height: "10rem",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div className="history-list">
              <ul className="history-entries">
                {(filteredProducts.length > 0
                  ? filteredProducts
                  : products
                ).map((product, index) => {
                  return (
                    <ClientItem
                      product={product}
                      key={index}
                      deleteProduct={deleteProduct}
                      isLoadingDelete={isLoadingDelete}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function ClientItem({ product, deleteProduct, isLoadingDelete }) {
  const [isMenu, setIsMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <li className="history-entry history-entry-establishments">
      <Modal
        title="Supprimer"
        open={isModalOpen}
        onOk={() => deleteProduct(product.uid)}
        onCancel={handleCancel}
        okText="Supprimer"
        cancelText="Cancel"
      >
        <p>Êtes-vous sûr de vouloir supprimer cet product ?</p>
        {isLoadingDelete && (
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "5rem",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Modal>

      <div className="history-code">{product.name.substring(0, 1)}</div>
      <div className="profile">{product.name}</div>
      <div className="items">
        {product.transportType === 1 ? "Transport Sanguin" : "Autre Transport"}
      </div>
      <div className="items">{product.addedBy}</div>

      <button className="items dotsButton" onClick={() => setIsMenu(!isMenu)}>
        {isMenu && (
          <div
            style={{
              position: "absolute",
              background: "white",
              marginBottom: 110,
              borderColor: "gray",
            }}
          >
            <Button
              size="large"
              block
              icon={<DeleteOutlined />}
              style={{ background: "rgb(220, 124, 124)", color: "white" }}
              onClick={() => {
                setIsMenu(!isMenu);
                setIsModalOpen(true);
              }}
            >
              Delete Product
            </Button>
          </div>
        )}
        <img src={action_dots_icon} alt="Action" title="Action" />
      </button>
    </li>
  );
}

export default Products;
