import React, { Component } from "react";
import { Link } from "react-router-dom";
//import "antd/dist/antd.css";
import logo from "../../assets/images/medikdom-logo.svg";
//import { Button, Checkbox, Form, Input } from "antd";

class PasswordRecovery extends Component {
  render() {
    const onFinish = (values) => {
      console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    return (
      <div
        style={{
          backgroundColor: "#F3F3F3",
          width: window.innerWidth,
          height: window.innerHeight,
        }}
      >
        {/*<div
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "758px",
              height: "664px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 20,
              boxShadow: "1px 2px 9px #F4AAB9",
              margin: "4em",
              padding: "1em",
            }}
          >
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="container">
                <Link to="/">
                  <img src={logo} className="logo" />
                  <h2 className="cgu-title">Mentions Légales</h2>
                </Link>
              </div>

              <div style={{ paddingRight: 85, paddingBottom: 20 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "35px",
                    color: "#3D3D3D",
                    paddingRight: 11,
                  }}
                >
                  <text>Mot de passe oublie?</text>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "13px",
                    color: "#3D3D3D",
                  }}
                >
                  <text>
                    Entrez votre mail, afin de recevoir un mail de
                    réinitialisation <br />
                    de votre nouveau mot passe.
                  </text>
                </div>
              </div>

              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input
                  placeholder="Email"
                  style={{
                    borderRadius: 10,
                    width: "430px",
                    height: "48px",
                    fontSize: "20px",
                  }}
                />
              </Form.Item>

              <Form.Item
                name="Confirmez"
                rules={[
                  { required: true, message: "Please input your e-mail!" },
                ]}
              >
                <Input
                  placeholder="Confirmez votre e-mail"
                  style={{
                    borderRadius: 10,
                    width: "430px",
                    height: "48px",
                    fontSize: "20px",
                  }}
                />
              </Form.Item>

              <div style={{ paddingTop: 30 }}>
                <Button
                  type="primary"
                  htmlType="Login"
                  size="large"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#FF4E4E",
                    borderColor: "#FF4E4E",
                    width: "430px",
                    height: "48px",
                    fontSize: "20px",
                  }}
                >
                  Réinitialiser mot de passe
                </Button>
              </div>
            </Form>
          </div>
        </div>*/}
      </div>
    );
  }
}

export default PasswordRecovery;
