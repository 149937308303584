import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import messages_icon from "../../assets/images/messages_icon.svg";
import logo from "../../assets/images/logo.svg";
import avatar from "../../assets/images/avatar.png";
import logout_icon from "../../assets/images/logout_icon.svg";
import notification_icon from "../../assets/images/notification_icon.svg";
import UserContext from "../../context/User/UserContext";

const Header = () => {
  const { user, logOutUser } = useContext(UserContext);
  const navigate = useNavigate();

  const logOut = () => {
    logOutUser();
    navigate("/login");
  };

  return (
    <header className="dahboardheader">
      <div className="dahboardheader__wrapper">
        <div className="dahboardheader__left">
          <div style={{ display: "flex" }}>
            <img src={logo} alt="logo" title="logo" />
            <div className="dahboardheader__logo2">MEDIK'DOM</div>
          </div>
        </div>

        <nav className="dahboardheader__right">
          <ul className="dahboardheader__nav">
            <li className="dahboardheader__nav__item">
              <div className="dahboardheader__nav__item__icon">
                <img
                  src={notification_icon}
                  alt="notifications"
                  title="notifications"
                />
              </div>
            </li>

            <li className="dahboardheader__nav__item">
              <div className="dahboardheader__nav__item__icon active">
                <img src={messages_icon} alt="messages" title="messages" />
              </div>
              <div className="dahboardheader__nav__item__title"></div>
            </li>

            <li className="dahboardheader__nav__item">
              <div className="dahboardheader__nav__item__icon simple">
                <img
                  src={
                    user.profile_picture_url ? user.profile_picture_url : avatar
                  }
                  alt="avatar"
                  title="avatar"
                  className="__full"
                />
              </div>
              <div className="dahboardheader__nav__item__title">
                {user.displayName ? user.displayName : "User"}
              </div>
            </li>
          </ul>

          <div onClick={logOut} className="dahboardheader__log">
            <div className="dahboardheader__nav__item__title">logout</div>
            <div className="dahboardheader__nav__item__icon simple">
              <img
                src={logout_icon}
                alt="logout"
                title="logout"
                className="__full"
              />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
