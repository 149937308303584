import React from "react";
import { Link } from "react-router-dom";
import Home from "../../assets/svg/home.svg";
import Document from "../../assets/svg/document.svg";
import Steering from "../../assets/svg/steering.svg";
import Hand from "../../assets/svg/hand.svg";
import Archive from "../../assets/svg/archive.svg";
import Time from "../../assets/svg/time.svg";
import Profile from "../../assets/svg/profile.svg";
import Box from "../../assets/svg/box.svg";
import Calendar from "../../assets/svg/calendar.svg";
import Tools from "../../assets/svg/tools.svg";

export default class Menu extends React.Component {
  constructor() {
    super();
  }

  render() {
    const menuData = [
      {
        icon: Home,
        title: "Dasboard",
        link: "/admin/dashboard",
      },
      {
        icon: Document,
        title: "Tickets",
        link: "/admin/ticket",
      },
      {
        icon: Steering,
        title: "Drivers",
        link: "/admin/drivers",
      },
      {
        icon: Hand,
        title: "Etablishments",
        link: "/admin/etablishments",
      },
      // {
      //   icon: Archive,
      //   title: "Archive",
      //   link: "/admin/archive",
      // },
      // {
      //   icon: Time,
      //   title: "History",
      //   link: "/admin/history",
      // },
      // {
      //   icon: Profile,
      //   title: "Profile",
      //   link: "/admin/profile",
      // },
      {
        icon: Box,
        title: "Product",
        link: "/admin/products",
      },
      // {
      //   icon: Calendar,
      //   title: "Calendar",
      //   link: "",
      // },
      {
        icon: Tools,
        title: "Config",
        link: "/admin/settings",
      },
    ];
    return (
      <div className="side-bar dahboardmenu">
        <nav>
          <ul>
            {menuData.map((data, key) => (
              <Link to={data.link} state={{ driver: {} }}>
                <li className={"dahboardmenu--item"} key={key}>
                  <div style={{ display: "flex" }}>
                    <div style={{ paddingRight: 10 }}>
                      <img src={data.icon} alt="icon" />
                    </div>
                    <div
                      className="dahboardmenu--item__title"
                      style={{ width: 250 }}
                    >
                      {data.title}
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </nav>
      </div>
    );
  }
}
