import React, { useContext, useState } from "react";
import "antd/dist/reset.css";
import logo from "../../assets/images/logo.svg";

import { Button, Checkbox, Form, Input, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import "../../assets/styles/styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import UserContext from "../../context/User/UserContext";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { updateUser } = useContext(UserContext);

  const logIn = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async ({ user }) => {
        const docSnap = await getDoc(doc(db, "admins", user.uid));
        if (docSnap.exists()) {
          const admin = { ...docSnap.data(), uid: docSnap.id, isLogged: true };
          updateUser(admin);
          localStorage.setItem("userMedik", JSON.stringify(admin));
          navigate("/admin/dashboard");
        } else {
          console.log("No such document!");
          toast.error("Error: THIS USER IS NOT AN ADMIN.");
        }
      })
      .catch((err) => {
        if (
          err.message === "Firebase: Error (auth/wrong-password)." ||
          err.message === "Firebase: Error (auth/user-not-found)."
        )
          return toast.error("Error: wrong credentials.");
        toast("Server error");
        console.log(err.message);
      })
      .finally(() => setLoading(false));
  };

  const onFinish = async (values) => {
    setLoading(true);
    var { email, password } = values;
    await logIn(email, password);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      style={{
        backgroundColor: "#F3F3F3",
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <ToastContainer />
      <div
        className="container"
        style={{
          backgroundColor: "#F3F3F3",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "758px",
            height: "664px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 20,
            boxShadow: "1px 2px 9px #F4AAB9",
            margin: "4em",
            padding: "1em",
            flexDirection: "column",
          }}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            style={{ display: "flex", flexDirection: "column" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="container">
              <div className="dahboardheader__left">
                <button
                  style={{ background: "transparent" }}
                  className="hover"
                  onClick={() => navigate("/")}
                >
                  <div style={{ display: "flex" }}>
                    <img src={logo} alt="logo" title="logo" height={180} />
                    <div className="dahboardheader__logo3">MEDIK'DOM</div>
                  </div>
                </button>
              </div>
            </div>
            <Form.Item
              //   label="Username"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email admin account!",
                },
              ]}
            >
              <Input
                placeholder="Email"
                style={{
                  borderRadius: 10,
                  width: "430px",
                  height: "48px",
                  // fontFamily: "Segoe UI",
                  fontSize: "20px",
                }}
              />
            </Form.Item>

            <Form.Item
              // label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                style={{
                  borderRadius: 10,
                  width: "430px",
                  height: "48px",
                  // fontFamily: "SegoeUI",
                  fontSize: "20px",
                }}
              />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Button
              type="primary"
              htmlType="Login"
              size="large"
              style={{
                borderRadius: 10,
                backgroundColor: "#FF4E4E",
                borderColor: "#FF4E4E",
                width: "430px",
                height: "48px",
                //   fontFamily: "SegoeUI",
                fontSize: "20px",
              }}
            >
              Login
            </Button>
            {loading && (
              <div
                style={{
                  marginBlock: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  height: "5rem",
                }}
              >
                <Spin size="large" />
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                //   fontFamily: "SegoeUI-Semibold",
                color: "#FF4E4E",
                fontSize: "15px",
                paddingTop: 5,
              }}
            >
              <Link style={{ color: "#FF4E4E" }} to="/PasswordRecovery">
                Mot de passe oublié ?
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
