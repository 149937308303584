import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd";
import { Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  collection,
  addDoc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import moment from "moment";
import Geocode from "react-geocode";

const TicketModal = ({ isModalOpen, setOpenModal, refresh }) => {
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [client, setClient] = useState(null);
  const [establishments, setEstablishments] = useState([]);
  const [newService, setNewService] = useState("");
  const [receptionAddress, setReceptionAddress] = useState("");
  const [depotAddress, setDepotAddress] = useState("");
  const today = new Date();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBqwwHc7bAGFG3P21C0xMPvsTyT7aSLj34",
  });
  Geocode.setApiKey("AIzaSyBqwwHc7bAGFG3P21C0xMPvsTyT7aSLj34");
  const mapRef = useRef(null);
  const [positionCollecte, setPositionCollecte] = useState({
    lat: parseFloat(16.2879702),
    lng: parseFloat(-61.4978015),
  });
  const [positionDepot, setPositionDepot] = useState({
    lat: parseFloat(16.2879702),
    lng: parseFloat(-61.4978015),
  });

  function handleLoad(map) {
    mapRef.current = map;
  }

  const clientSchema = Yup.object().shape({
    client: Yup.object().required("Required"),
    service: newService === "" && Yup.string().required("Required"),
    reception: step > 0 && Yup.object().required("Required"),
    deposer: step > 1 && Yup.object().required("Required"),
  });

  const getAndIncrementTicketNo = async (ticketRef) => {
    try {
      await runTransaction(db, async (transaction) => {
        const counterRef = doc(db, "Counters", "tickets");
        const counters = await getDoc(counterRef);
        if (!counters.exists()) {
          throw "Document does not exist!";
        }
        const newTicketNo = counters.data().counterValue + 1;
        transaction.update(counterRef, { counterValue: newTicketNo });
        console.log(newTicketNo);
        transaction.update(ticketRef, { ticketNo: newTicketNo });
      });
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
  };

  const handleSubmitService = async (client) => {
    try {
      let newServiceObj = { name: newService, enable: true };
      const updateRef = doc(db, "clients", client.uid);
      await updateDoc(updateRef, {
        services: arrayUnion(newServiceObj),
      });
    } catch (error) {
      console.log(error);
      toast.error("Error adding service");
    }
  };

  const createTicket = async (values, r) => {
    try {
      setLoading(true);
      newService !== "" && (await handleSubmitService(values.client));
      await addDoc(collection(db, "tickets"), values)
        .then(async (docRef) => {
          await getAndIncrementTicketNo(docRef).then(() => {
            refresh();
            toast.success("Ticket créé");
            setOpenModal();
            r();
          });
        })
        .catch((err) => {
          console.log(err.message);
          toast.error("Server error");
        });
    } catch (error) {
      console.log(error.message);
      toast.error("Server error");
    } finally {
      setLoading(false);
    }
  };

  const getEstablishments = async () => {
    setLoading(true);
    setEstablishments([]);
    await getDocs(
      query(collection(db, "clients"), orderBy("enterprise", "asc"))
    )
      .then((clients) => {
        clients.forEach((doc) => {
          setEstablishments((prev) => [
            ...prev,
            { ...doc.data(), uid: doc.id },
          ]);
        });
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => setLoading(false));
  };

  const setClientByUid = (uid) => {
    const found = establishments.find((element) => element.uid == uid);
    setClient(found);
    if (
      found?.facturationAddress?.addressLine1?.address &&
      found?.facturationAddress?.addressLine1?.region
    )
      setPositionCollecte({
        lat: parseFloat(
          found?.facturationAddress?.addressLine1?.region?.latitude
        ),
        lng: parseFloat(
          found?.facturationAddress?.addressLine1?.region?.longitude
        ),
      });
    setPositionDepot({
      lat: parseFloat(
        found?.facturationAddress?.addressLine1?.region?.latitude
      ),
      lng: parseFloat(
        found?.facturationAddress?.addressLine1?.region?.longitude
      ),
    });
    return {
      addresses: found.addresses,
      location: found.clientLocation,
      name: found.enterprise,
      services: found.services,
      uid: uid,
    };
  };

  useEffect(() => {
    getEstablishments();
  }, []);

  return (
    <Modal
      title="Créer un ticket"
      open={isModalOpen}
      onCancel={() => {
        setOpenModal();
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="Annuler"
    >
      {isLoading ? (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            height: "5rem",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div
          className="input-section"
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {step !== 0 && (
            <Button
              type="primary"
              style={{
                borderRadius: 10,
                backgroundColor: "red",
                height: 48,
                marginBlock: 20,
              }}
              onClick={() => setStep(step == 1 ? 0 : 1)}
            >
              Retour
            </Button>
          )}
          <Formik
            validationSchema={clientSchema}
            initialValues={{
              client: null,
              deposer: null,
              reception: null,
              transport: 1,
              service: "",
              ticketNo: "000", //-------------
              urgence: "Relative",
              // By default
              createdAt: today,
              owner: { type: "admin", uid: "9OrYrLeSWtbVJRuLw13yLIyLJEq1" },
              scheduleDate: moment(today).format("MM/DD/yyyy").toString(),
              scheduled: false,
              scheduleTime: "00:00",
              status: "initiated",
              transports: [],
            }}
            onSubmit={async (values, { resetForm }) => {
              step == 2
                ? await createTicket(values, resetForm)
                : setStep(step + 1);
            }}
          >
            {({ handleSubmit, values, errors, touched, setFieldValue }) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {step == 0 && (
                  <>
                    <div className="form-title">Information</div>
                    <label className="form-label">
                      Type de transport et service
                    </label>
                    <select
                      className="form-input"
                      component="select"
                      placeholder="Select service"
                      style={{ marginBottom: "20px" }}
                      disabled={isLoading}
                      onChange={(e) =>
                        setFieldValue("transport", parseInt(e.target.value))
                      }
                    >
                      <option value={1}>Transport Sanguin</option>
                      <option value={2}>Autre Transport</option>
                    </select>

                    <label className="form-label">ÉTABLISSEMENT</label>
                    {errors.client && touched.client ? (
                      <div style={{ color: "red" }}>{errors.client}</div>
                    ) : null}
                    <select
                      className="form-input"
                      component="select"
                      placeholder="Select service"
                      style={{ marginBottom: "20px" }}
                      disabled={isLoading}
                      onChange={(e) => {
                        setFieldValue("client", setClientByUid(e.target.value));
                        setFieldValue("service", "");
                      }}
                    >
                      {establishments
                        ? establishments.map((establishment, i) => {
                            return (
                              <option key={i} value={establishment.uid}>
                                {establishment.enterprise}
                              </option>
                            );
                          })
                        : null}
                    </select>

                    <label className="form-label">Service</label>
                    {errors.service && touched.service ? (
                      <div style={{ color: "red" }}>{errors.service}</div>
                    ) : null}
                    <Field
                      name="service"
                      className="form-input"
                      component="select"
                      placeholder="Select service"
                      style={{ marginBottom: "20px" }}
                      disabled={isLoading}
                      value={values.service}
                    >
                      {" "}
                      {client ? (
                        client.services.map((service, i) => {
                          return (
                            <option key={i} value={service.name}>
                              {service.name}
                            </option>
                          );
                        })
                      ) : (
                        <option style={{ color: "gray" }} value={""}>
                          NO ÉTABLISSEMENT
                        </option>
                      )}
                    </Field>
                    <input
                      style={{ marginBottom: "20px" }}
                      placeholder="Ajouter un service"
                      onChange={(e) => {
                        setFieldValue("service", e.target.value);
                        setNewService(e.target.value);
                      }}
                      value={newService}
                      className="form-input"
                    />

                    <label className="form-label">Urgence</label>
                    <Field
                      name="urgence"
                      className="form-input"
                      component="select"
                      style={{ marginBottom: "20px" }}
                      disabled={isLoading}
                    >
                      <option value="Relative">Relative</option>
                      <option value="Urgence">Urgence</option>
                      <option value="Urgence Vitale">Urgence Vitale</option>
                    </Field>
                  </>
                )}

                {step == 1 && (
                  <>
                    <label className="form-label">Collecte</label>
                    <p>
                      Address:{" "}
                      {receptionAddress != ""
                        ? receptionAddress
                        : "No address selected"}
                    </p>
                    {errors.reception && touched.reception ? (
                      <div style={{ color: "red" }}>{errors.reception}</div>
                    ) : null}
                    {!isLoaded ? (
                      <h1>Loading...</h1>
                    ) : (
                      <GoogleMap
                        onDragEnd={async () => {
                          if (!mapRef.current) return;
                          const newPos = mapRef.current.getCenter().toJSON();
                          let address = await Geocode.fromLatLng(
                            newPos.lat,
                            newPos.lng
                          ).then(
                            (response) => {
                              return response.results[0].formatted_address;
                            },
                            (error) => {
                              console.error(error);
                              return "error";
                            }
                          );
                          setReceptionAddress(address);
                          setFieldValue("reception.location", {
                            address: address,
                            region: {
                              latitude: newPos.lat,
                              longitude: newPos.lng,
                              latitudeDelta: 0.0014939354628467072,
                              longitudeDelta: 0.0007178261876106262,
                            },
                          });
                          setPositionCollecte(newPos);
                        }}
                        onLoad={handleLoad}
                        mapContainerClassName="map-container"
                        zoom={13}
                        center={positionCollecte}
                      >
                        <Marker position={positionCollecte}></Marker>
                      </GoogleMap>
                    )}
                  </>
                )}
                {step == 2 && (
                  <>
                    <label className="form-label">Dépot</label>
                    <p>
                      Address:{" "}
                      {depotAddress != ""
                        ? depotAddress
                        : "No address selected"}
                    </p>
                    {errors.deposer && touched.deposer ? (
                      <div style={{ color: "red" }}>{errors.deposer}</div>
                    ) : null}
                    {!isLoaded ? (
                      <h1>Loading...</h1>
                    ) : (
                      <GoogleMap
                        onDragEnd={async () => {
                          if (!mapRef.current) return;
                          const newPos = mapRef.current.getCenter().toJSON();
                          let address = await Geocode.fromLatLng(
                            newPos.lat,
                            newPos.lng
                          ).then(
                            (response) => {
                              return response.results[0].formatted_address;
                            },
                            (error) => {
                              console.error(error);
                            }
                          );
                          setDepotAddress(address);
                          setFieldValue("deposer.location", {
                            address: address,
                            region: {
                              latitude: newPos.lat,
                              longitude: newPos.lng,
                              latitudeDelta: 0.0014939354628467072,
                              longitudeDelta: 0.0007178261876106262,
                            },
                          });
                          setPositionDepot(newPos);
                        }}
                        onLoad={handleLoad}
                        mapContainerClassName="map-container"
                        zoom={13}
                        center={positionDepot}
                      >
                        <Marker position={positionDepot}></Marker>
                      </GoogleMap>
                    )}
                  </>
                )}
                <Button
                  type="primary"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "red",
                    display: "flex",
                    height: 48,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleSubmit}
                >
                  {step == 2 ? "Enregistrer" : "Suivant"}
                </Button>
              </div>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export default TicketModal;
