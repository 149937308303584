import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd";
import { Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const EtablishmentsModal = ({ isModalOpen, setOpenModal, refresh }) => {
  const [isLoading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceName, setServiceName] = useState();
  const today = new Date();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBqwwHc7bAGFG3P21C0xMPvsTyT7aSLj34",
  });
  const mapRef = useRef(null);
  const [position, setPosition] = useState({
    lat: 16.2879702,
    lng: -61.4978015,
  });

  function handleLoad(map) {
    mapRef.current = map;
  }

  const clientSchema = Yup.object().shape({
    referentName: Yup.string().min(5, "Too Short").required("Required"),
    enterprise: Yup.string().min(2, "Too Short").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    telephone: Yup.number().required("Required"),
    mobileNumber: Yup.number().required("Required"),
  });

  const createClient = async (values) => {
    setLoading(true);
    await addDoc(collection(db, "clients"), { ...values, services: services })
      .then(async () => {
        refresh();
        toast.success("Etablishment créé");
        setOpenModal();
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Ajouter Etablishment"
      open={isModalOpen}
      onCancel={() => {
        setOpenModal();
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="Annuler"
    >
      <div className="input-section" style={{ width: "100%" }}>
        <Formik
          validationSchema={clientSchema}
          initialValues={{
            referentName: "",
            enterprise: "",
            zone: "Guadeloupe",
            email: "",
            telephone: "",
            mobileNumber: "",
            // Adresses values
            clientLocation: {
              latitude: 16.239200509242828,
              longitude: -61.5852122940123,
              latitudeDelta: 0.0014939354628467072,
              longitudeDelta: 0.0007178261876106262,
            },
            facturationAddress: {
              addressLine1: {
                address: "",
                region: {
                  latitude: position.lat,
                  longitude: position.lng,
                  latitudeDelta: 0.0922,
                  longitudeDelta: 0.0421,
                },
              },
              addressLine2: "",
              addressLine3: "",
              ville: "",
              department: "",
              postalCode: "",
              pays: "",
              profile_picture_url: null,
              profile_picture_id: null,
            },
            createdAt: today,
            addresses: [],
          }}
          onSubmit={async (values, { resetForm }) => {
            await createClient(values);
            resetForm();
          }}
        >
          {({ handleSubmit, values, errors, touched, setFieldValue }) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="form-title">Information</div>
              <label className="form-label">NOM ET PRÉNOM DU RÉFÉRENT</label>
              {errors.referentName && touched.referentName ? (
                <div style={{ color: "red" }}>{errors.referentName}</div>
              ) : null}
              <Field
                type="text"
                name="referentName"
                placeholder="Nom"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />

              <label className="form-label">ÉTABLISSEMENT</label>
              {errors.enterprise && touched.enterprise ? (
                <div style={{ color: "red" }}>{errors.enterprise}</div>
              ) : null}
              <Field
                type="text"
                name="enterprise"
                placeholder="ÉTABLISSEMENT"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />

              <label className="form-label">ZONE</label>
              <Field
                name="zone"
                className="form-input"
                component="select"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              >
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Martinique">Martinique</option>
              </Field>

              <label className="form-label">EMAIL</label>
              {errors.email && touched.email ? (
                <div style={{ color: "red" }}>{errors.email}</div>
              ) : null}
              <Field
                type="text"
                name="email"
                placeholder="email@test.com"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />

              <label className="form-label">TELEPHONE</label>
              {errors.telephone && touched.telephone ? (
                <div style={{ color: "red" }}>{errors.telephone}</div>
              ) : null}
              <Field
                type="number"
                name="telephone"
                placeholder="124567"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />

              <label className="form-label">MOBILE</label>
              {errors.mobileNumber && touched.mobileNumber ? (
                <div style={{ color: "red" }}>{errors.mobileNumber}</div>
              ) : null}
              <Field
                type="number"
                name="mobileNumber"
                placeholder="124567"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />
              {/* ---------------------------------- ADDRESSS -------------------------------------------------------------- */}
              <div className="form-title">Adressse de facturation</div>
              <label className="form-label">Address line 1</label>
              {!values.facturationAddress.addressLine1.address ||
              values.facturationAddress.addressLine1.address.length < 6 ? (
                <div style={{ color: "red" }}>
                  Longueur requise et minimale 6
                </div>
              ) : null}
              <Field
                type="text"
                name="facturationAddress.addressLine1.address"
                placeholder="124567"
                required
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />

              <label className="form-label">
                Sélectionner la position en faisant glisser la carte
              </label>
              {!isLoaded ? (
                <h1>Loading...</h1>
              ) : (
                <GoogleMap
                  onDragEnd={() => {
                    if (!mapRef.current) return;
                    const newPos = mapRef.current.getCenter().toJSON();
                    setFieldValue(
                      "facturationAddress.addressLine1.region.latitude",
                      newPos.lat
                    );
                    setFieldValue(
                      "facturationAddress.addressLine1.region.longitude",
                      newPos.lng
                    );
                    setPosition(newPos);
                  }}
                  onLoad={handleLoad}
                  mapContainerClassName="map-container"
                  zoom={13}
                  center={position}
                >
                  <Marker position={position}></Marker>
                </GoogleMap>
              )}
              <label className="form-label">Address line 2</label>
              <Field
                type="text"
                name="facturationAddress.addressLine2"
                placeholder="124567"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />
              <label className="form-label">Address line 3</label>
              <Field
                type="text"
                name="facturationAddress.addressLine3"
                placeholder="124567"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
                disabled={isLoading}
              />
              <div className="input-section">
                <div className="input-group">
                  <label className="form-label">Ville</label>
                  <Field
                    type="text"
                    name="facturationAddress.ville"
                    placeholder="Ville"
                    className="form-input form-input-br-btm"
                    style={{ marginBottom: "20px" }}
                    disabled={isLoading}
                  />
                </div>
                <div className="input-group">
                  <label className="form-label">Departement</label>
                  <Field
                    type="text"
                    name="facturationAddress.department"
                    placeholder="Dpto"
                    className="form-input form-input-br-btm"
                    style={{ marginBottom: "20px" }}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="input-section">
                <div className="input-group">
                  <label className="form-label">Code Postal</label>
                  <Field
                    type="text"
                    name="facturationAddress.postalCode"
                    placeholder="124567"
                    className="form-input form-input-br-btm"
                    style={{ marginBottom: "20px" }}
                    disabled={isLoading}
                  />
                </div>
                <div className="input-group">
                  <label className="form-label">Pays</label>
                  <Field
                    type="text"
                    name="facturationAddress.pays"
                    placeholder="124567"
                    className="form-input form-input-br-btm"
                    style={{ marginBottom: "20px" }}
                    disabled={isLoading}
                  />
                </div>
              </div>
              {/* ---------------------------------- Services -------------------------------------------------------------- */}
              <div className="form-title">Services</div>
              <label className="form-label">Créer un nouveau service</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  //name="facturationAddress.pays"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                  placeholder="service"
                  className="form-input form-input-br-btm"
                  style={{ marginBottom: "20px" }}
                  disabled={isLoading}
                />
                <Button
                  type="primary"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "red",
                    display: "flex",
                    height: 48,
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 20,
                  }}
                  disabled={isLoading}
                  onClick={() => {
                    setServices((prev) => [
                      ...prev,
                      { name: serviceName, enabled: true },
                    ]);
                    setServiceName("");
                  }}
                >
                  <PlusCircleOutlined style={{ fontSize: "20px" }} />
                </Button>
              </div>
              {services.length > 0 ? (
                services.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="input-group-check"
                      style={{ color: "#3c3c3c", marginBottom: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-around",
                        }}
                      >
                        <label>{item.name}</label>
                        <Button
                          onClick={() => {
                            let newArray = [...services];
                            newArray.splice(i, 1);
                            setServices(newArray);
                          }}
                          danger
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ marginBottom: 20 }}>
                  <label className="form-label">
                    Aucun service n'a encore été ajouté
                  </label>
                </div>
              )}

              <Button
                type="primary"
                style={{
                  borderRadius: 10,
                  backgroundColor: "red",
                  display: "flex",
                  height: 48,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={
                  !values.facturationAddress.addressLine1.address ||
                  values.facturationAddress.addressLine1.address.length < 6
                    ? null
                    : handleSubmit
                }
              >
                Enregistrer
              </Button>
            </div>
          )}
        </Formik>
      </div>

      {isLoading && (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            height: "5rem",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </Modal>
  );
};

export default EtablishmentsModal;
