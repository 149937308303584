import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/home/home";
import Cgu from "../pages/home/cgu";
import Privacy from "../pages/home/privacy";
import Login from "../pages/home/login";
import PasswordRecovery from "../pages/home/PasswordRecovery";
import Dashboard from "../pages/admin/dashboard";
import Tracking from "../pages/admin/tracking";
import Ticket from "../pages/admin/ticket";
import Archive from "../pages/admin/archive";
import History from "../pages/admin/history";
import Drivers from "../pages/admin/drivers";
import Etablishments from "../pages/admin/etablishments";
import Products from "../pages/admin/products";
import Settings from "../pages/admin/settings";
import Reminder from "../pages/admin/reminder";
import Profile from "../pages/admin/profile";
import CreateProduct from "../pages/admin/create-products";
import EtablishmentProfile from "../pages/admin/etablishment-profile";
import ProtectedRoute from "./ProtectedRoute";
import { useContext, useEffect } from "react";
import UserContext from "../context/User/UserContext";
import RedirectsAdmins from "./RedirectsAdmins";
import MyPromoPrivacyPolicy from "../pages/mypromo";

const Navigation = () => {
  const { user, updateUser, isLogged } = useContext(UserContext);
  const userLocalStorage = JSON.parse(localStorage.getItem("userMedik"));

  useEffect(() => {
    if (userLocalStorage) {
      console.log("user - true ");
      updateUser(userLocalStorage);
    } else {
      console.log("user - false ");
    }
  }, []);

  return (
    <Router>
      <Routes>
        {/* LANDING ROUTES */}
        <Route element={<RedirectsAdmins isAllowed={isLogged} />}>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="/cgu" element={<Cgu />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/PasswordRecovery" element={<PasswordRecovery />} />
          <Route path="/mypromo" element={<MyPromoPrivacyPolicy />} />
        </Route>
        {/* ADMIN ROUTES */}
        <Route
          element={
            <ProtectedRoute isAllowed={!!userLocalStorage || isLogged} />
          }
        >
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/tracking" element={<Tracking />} />
          <Route path="/admin/ticket" element={<Ticket />} />
          <Route path="/admin/archive" element={<Archive />} />
          <Route path="/admin/history" element={<History />} />
          <Route path="/admin/drivers" element={<Drivers />} />
          <Route path="/admin/drivers/:uid" element={<Profile />} />
          <Route path="/admin/etablishments" element={<Etablishments />} />
          <Route path="/admin/products" element={<Products />} />
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/reminder" element={<Reminder />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/create-product" element={<CreateProduct />} />
          <Route
            path="/admin/etablishment-profile/:uid"
            element={<EtablishmentProfile />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default Navigation;
