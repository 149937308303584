import { Button, Modal, Spin, Switch } from "antd";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  collection,
  addDoc,
  setDoc,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, auth, functions } from "../../config/firebase";
import { toast } from "react-toastify";
import { httpsCallable } from "firebase/functions";

const LivreursModal = ({ isModalOpen, setOpenModal, refresh }) => {
  const [isLoading, setLoading] = useState(false);

  const [createTicket, setCreateTicket] = useState(false);
  const [scheduleTicket, setScheduleTicket] = useState(false);
  const [createDriver, setCreateDriver] = useState(false);
  const [modifyDriver, setModifyDriver] = useState(false);
  const [deleteDriver, setDeleteDriver] = useState(false);
  const [createClient, setCreateClient] = useState(false);
  const [modifyClient, setModifyClient] = useState(false);
  const [deleteClient, setDeleteClient] = useState(false);

  const createDriverFB = httpsCallable(functions, "createDriver");

  const today = new Date();

  const clientSchema = Yup.object().shape({
    lastName: Yup.string().min(5, "Too Short").required("Required"),
    firstName: Yup.string().min(2, "Too Short").required("Required"),
    vehicule: Yup.string().min(2, "Too Short").required("Required"),
    plaque: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    telephone: Yup.number().required("Required"),
  });

  const createDriverr = async (values) => {
    setLoading(true);

    const temp = {
      ...values,
    };
    createDriverFB({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: "+11234567890",
    })
      .then(async (res) => {
        if (res.data.success) {
          await setDoc(doc(db, "drivers", `${res.data.uid}`), {
            ...temp,
            userPassword: res.data.password,
            status: "online",
            allow_notification: true,
            activeTicket: null,
            activity: null,
            chats: [],
            profile_picture_id: null,
            profile_picture_url: null,
            history: [],
          });
          refresh();
          toast.success("Livreur créé, and mot de passe envoyé par email");
          setOpenModal();
        } else {
          console.log(
            "Error while creating user => " + JSON.stringify(res.data)
          );
          toast.error(`${res.data.message.message}`);
        }
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Server error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Ajouter Livreur"
      open={isModalOpen}
      onCancel={() => {
        setOpenModal();
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="Annuler"
    >
      <div className="input-section" style={{ width: "100%" }}>
        <Formik
          validationSchema={clientSchema}
          initialValues={{
            lastName: "",
            firstName: "",
            vehicule: "",
            plaque: "",
            email: "",
            telephone: "",
            createTicket: false,
            scheduleTicket: false,
            createDriver: false,
            modifyDriver: false,
            deleteDriver: false,
            createClient: false,
            modifyClient: false,
            deleteClient: false,
            createdAt: today,
          }}
          onSubmit={async (values, { resetForm }) => {
            await createDriverr(values);
            resetForm();
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue }) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="form-title">Information</div>
              <label className="form-label">NOM</label>
              {errors.lastName && touched.lastName ? (
                <div style={{ color: "red" }}>{errors.lastName}</div>
              ) : null}
              <Field
                type="text"
                name="lastName"
                placeholder="Nom"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
              />

              <label className="form-label">PRÉNOM</label>
              {errors.firstName && touched.firstName ? (
                <div style={{ color: "red" }}>{errors.firstName}</div>
              ) : null}
              <Field
                type="text"
                name="firstName"
                placeholder="Prenom"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
              />

              <label className="form-label">VEHICLE</label>
              {errors.vehicule && touched.vehicule ? (
                <div style={{ color: "red" }}>{errors.vehicule}</div>
              ) : null}
              <Field
                type="text"
                name="vehicule"
                placeholder="vehicle"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
              />

              <label className="form-label">PLAQUE D'IMMATRICULATION</label>
              {errors.plaque && touched.plaque ? (
                <div style={{ color: "red" }}>{errors.plaque}</div>
              ) : null}
              <Field
                type="text"
                name="plaque"
                placeholder="Plaque d'immatriculation"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
              />

              <label className="form-label">EMAIL</label>
              {errors.email && touched.email ? (
                <div style={{ color: "red" }}>{errors.email}</div>
              ) : null}
              <Field
                type="text"
                name="email"
                placeholder="email@test.com"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
              />

              <label className="form-label">TELEPHONE</label>
              {errors.telephone && touched.telephone ? (
                <div style={{ color: "red" }}>{errors.telephone}</div>
              ) : null}
              <Field
                type="number"
                name="telephone"
                placeholder="124567"
                className="form-input form-input-br-btm"
                style={{ marginBottom: "20px" }}
              />

              <div className="form-title">Gestion des rôles:</div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à créer un ticket
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `54%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={createTicket ? { backgroundColor: "red" } : {}}
                    checked={createTicket}
                    onChange={(checked) => {
                      setCreateTicket(checked);
                      setFieldValue("createTicket", checked);
                    }}
                  />
                </div>
              </div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à programmer un ticket
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `42.5%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={scheduleTicket ? { backgroundColor: "red" } : {}}
                    checked={scheduleTicket}
                    onChange={(checked) => {
                      setScheduleTicket(checked);
                      setFieldValue("scheduleTicket", checked);
                    }}
                  />
                </div>
              </div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à créer un nouveau livreur
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `37.5%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={createDriver ? { backgroundColor: "red" } : {}}
                    checked={createDriver}
                    onChange={(checked) => {
                      setCreateDriver(checked);
                      setFieldValue("createDriver", checked);
                    }}
                  />
                </div>
              </div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à modifier un livreur
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `48%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={modifyDriver ? { backgroundColor: "red" } : {}}
                    checked={modifyDriver}
                    onChange={(checked) => {
                      setModifyDriver(checked);
                      setFieldValue("modifyDriver", checked);
                    }}
                  />
                </div>
              </div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à supprimer un livreur
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `44.8%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={deleteDriver ? { backgroundColor: "red" } : {}}
                    checked={deleteDriver}
                    onChange={(checked) => {
                      setDeleteDriver(checked);
                      setFieldValue("deleteDriver", checked);
                    }}
                  />
                </div>
              </div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à ajouter un nouvel établishment
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `27.5%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={createClient ? { backgroundColor: "red" } : {}}
                    checked={createClient}
                    onChange={(checked) => {
                      setCreateClient(checked);
                      setFieldValue("createClient", checked);
                    }}
                  />
                </div>
              </div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à modifier un nouvel établishment
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `25.5%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={modifyClient ? { backgroundColor: "red" } : {}}
                    checked={modifyClient}
                    onChange={(checked) => {
                      setModifyClient(checked);
                      setFieldValue("modifyClient", checked);
                    }}
                  />
                </div>
              </div>

              <div className="containerSwitch">
                <label className="form-label">
                  Autorisation à supprimer un établishment
                </label>
                <div
                  style={{
                    justifyContent: "end",
                    width: `34%`,
                    display: "flex",
                  }}
                >
                  <Switch
                    style={deleteClient ? { backgroundColor: "red" } : {}}
                    checked={deleteClient}
                    onChange={(checked) => {
                      setDeleteClient(checked);
                      setFieldValue("deleteClient", checked);
                    }}
                  />
                </div>
              </div>

              <Button
                type="primary"
                style={{
                  borderRadius: 10,
                  backgroundColor: "red",
                  display: "flex",
                  height: 48,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleSubmit}
              >
                Enregistrer
              </Button>
            </div>
          )}
        </Formik>
      </div>

      {isLoading && (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            height: "5rem",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </Modal>
  );
};

export default LivreursModal;
