import React from "react";
import { Link } from "react-router-dom";

import logo from "../assets/images/mdkd_logo.svg";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import btnicon from "../assets/images/btn_icon.svg";

export default class Footer extends React.Component {
  constructor() {
    super();
    this.checkMailValidation = this.checkMailValidation.bind(this);
    this.state = { formValid: false };
  }

  checkMailValidation = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(e.target.value)) {
      this.setState({ formValid: true });
    } else {
      this.setState({ formValid: false });
    }
  };

  render() {
    return (
      <footer className="footer" id="contact">
        <div className="container container--main">
          <div className="section__eq-sec">
            <div>
              <div>
                <h3 className="body-section__titleM">Qui sommes-nous ?</h3>
                <h2 className="body-section__titleG">Contact</h2>
              </div>

              <div className="footer__address">
                <h3 className="footer__address__title">Martinique</h3>
                <div>
                  <p className="__address-el">MEDIK'DOM, LAURICISQUE</p>
                  <p className="__address-el">97110 POINTE-À-PITRE</p>
                  <p className="__address-el">+590 690 920 727</p>
                  <p className="__address-el">contact@medik-dom.fr</p>
                </div>
              </div>

              <div>
                <div className="footer__logo">
                  <Link to="/">
                    <img src={logo} title="Medikdom Logo" alt="Medikdom Logo" />
                  </Link>
                </div>

                <div className="footer__social">
                  <a href="#" className="__item">
                    <img src={facebook} title="" alt="" />
                  </a>

                  <a href="#" className="__item">
                    <img src={twitter} title="" alt="" />
                  </a>

                  <a href="#" className="__item">
                    <img src={instagram} title="" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div>
                <h3 className="body-section__titleM">envoyer un message</h3>
                <h2 className="body-section__titleG">CONTACTEZ-NOUS</h2>
              </div>

              <div>
                <form
                  action="https://us-central1-medik-dom.cloudfunctions.net/sendmail"
                  method="get"
                  className="form"
                >
                  <div className="form__group">
                    <input
                      required
                      type="text"
                      className="form__input"
                      name="name"
                      placeholder="Nom"
                    />
                  </div>

                  <div className="form__group form__group--db">
                    <input
                      required
                      type="email"
                      className="form__input"
                      name="email"
                      placeholder="Email"
                      onChange={this.checkMailValidation}
                    />

                    <input
                      required
                      type="text"
                      className="form__input"
                      name="phone"
                      placeholder="Téléphone"
                    />
                  </div>

                  <div className="form__group">
                    <textarea
                      required
                      className="form__textarea"
                      name="content"
                      placeholder="Votre message"
                    ></textarea>
                  </div>

                  {this.state.formValid && (
                    <div className="form__group-submit">
                      <input
                        type="submit"
                        value="envoyer"
                        className="button button--light form__submit"
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>

          <div className="footer__bottom">
            <div className="section__db">
              <div>
                <div className="section__db">
                  <Link to="/cgu">Mentions légales</Link>
                  <Link to="/privacy">Politique de confidentialité</Link>
                </div>

                <div>
                  © 2020 MEDIK'DOM - Tous les droits sont réservés. Designed by{" "}
                  <a
                    href="https://whaaat.studio/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    Whaaat Studio
                  </a>
                </div>
              </div>

              <div className="btn">
                <div className="button button--wIcon">
                  <div>
                    <img src={btnicon} title="" alt="" />
                  </div>
                  <Link to="/login">Admin</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
