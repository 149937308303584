import React, { Component } from "react";
import "../../assets/styles/styles.scss";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

import Metadata from "../../components/Metadata";
import logo from "../../assets/images/mdkd_logo.svg";

class Cgu extends Component {
  render() {
    return (
      <div>
        <Metadata title="Mentions Légales" />

        <header className="header-cgu">
          <div className="container container--main">
            <Link to="/">
              <img src={logo} className="logo" />
              <h2 className="cgu-title">Mentions Légales</h2>
            </Link>
          </div>
        </header>

        <div className="container container--main">
          <div className="cgu-section">
            <h3 className="cgu-section__title">Mentions légales</h3>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                1. Présentation du site :
              </h4>
              <div>
                <p className="cgu-article">
                  Conformément aux dispositions des articles 6-III et 19 de la
                  Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans
                  l’économie numérique, dite L.C.E.N., nous portons à la
                  connaissance des utilisateurs et visiteurs du site :
                  www.medik-dom.fr les informations suivantes :
                </p>

                <div className="cgu-article cgu-article--sub">
                  <h4 className="cgu-section__group__title">
                    Informations légales :
                  </h4>
                  <div>
                    Nom de la Société :{" "}
                    <span className="font-bold">XXXXXX</span>
                  </div>
                  <div>
                    Adresse :{" "}
                    <span className="font-bold">
                      LAURICISQUE 97110 POINTE-À-PITRE
                    </span>
                  </div>
                  <div>
                    Tél : <span className="font-bold">+590 690 920 727</span>
                  </div>
                  <div>
                    Au Capital de : <span className="font-bold">XXXX €</span>
                  </div>
                  <div>
                    SIRET : <span className="font-bold">XXXXXXXXXXXXXXX</span>{" "}
                    R.C.S. :{" "}
                    <span className="font-bold">POINTE-À-PITRE B XXX XXX</span>
                  </div>
                  <div>
                    Adresse de courrier électronique :{" "}
                    <a href="mailto:m.fillaux@gmail.com">m.fillaux@gmail.com</a>
                  </div>
                  <div>
                    Le Créateur du site est :{" "}
                    <span className="font-bold">WHAAAT STUDIO</span>
                  </div>
                  <div>
                    Le Responsable de la publication est :{" "}
                    <span className="font-bold">Miguel FILLAUX</span>
                  </div>
                  <div>
                    Contactez le responsable de la publication:{" "}
                    <a href="mailto:m.fillaux@gmail.com">m.fillaux@gmail.com</a>
                  </div>
                  <div>
                    Le responsable de la publication est{" "}
                    <span className="font-bold">une personne morale</span>
                  </div>
                  <div>
                    Le Webmaster est :{" "}
                    <span className="font-bold">WHAAAT STUDIO</span>
                  </div>
                  <div>
                    Contactez le Webmaster :{" "}
                    <a href="mailto:hello@whaaat.studio">hello@whaaat.studio</a>
                  </div>
                  <div>
                    L’hébergeur du site est :{" "}
                    <span className="font-bold">
                      Heroku Services - Salesforce.com, inc., 415 Mission
                      Street, Suite 300, San Francisco, California 94105
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                2. Description des services fournis :
              </h4>
              <div>
                <p className="cgu-article">
                  Le site{" "}
                  <a target="__blank" href="https://www.medik-dom.fr">
                    www.medik-dom.fr
                  </a>{" "}
                  a pour objet de fournir une information concernant l’ensemble
                  des activités de la société.
                </p>
                <p className="cgu-article">
                  Le propriétaire du site s’efforce de fournir sur le site{" "}
                  <a href="https://www.medik-dom.fr">www.medik-dom.fr</a>
                  des informations aussi précises que possible. Toutefois, il ne
                  pourra être tenue responsable des omissions, des inexactitudes
                  et des carences dans la mise à jour, qu’elles soient de son
                  fait ou du fait des tiers partenaires qui lui fournissent ces
                  informations. Tous les informations proposées sur le site{" "}
                  <a href="https://www.medik-dom.fr">www.medik-dom.fr</a> sont
                  données à titre indicatif, sont non exhaustives, et sont
                  susceptibles d’évoluer. Elles sont données sous réserve de
                  modifications ayant été apportées depuis leur mise en ligne.
                </p>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                3. Propriété intellectuelle et contrefaçons :
              </h4>
              <div>
                <p className="cgu-article">
                  Le propriétaire du site est propriétaire des droits de
                  propriété intellectuelle ou détient les droits d’usage sur
                  tous les éléments accessibles sur le site, notamment les
                  textes, images, graphismes, logo, icônes, sons, logiciels…
                </p>

                <p className="cgu-article">
                  Toute reproduction, représentation, modification, publication,
                  adaptation totale ou partielle des éléments du site, quel que
                  soit le moyen ou le procédé utilisé, est interdite, sauf
                  autorisation écrite préalable à l’email :{" "}
                  <a href="mailto:hello@whaaat.studio">hello@whaaat.studio</a>.
                </p>

                <p className="cgu-article">
                  Toute exploitation non autorisée du site ou de l’un quelconque
                  de ces éléments qu’il contient sera considérée comme
                  constitutive d’une contrefaçon et poursuivie conformément aux
                  dispositions des articles L.335-2 et suivants du Code de
                  Propriété Intellectuelle.
                </p>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                4. Liens hypertextes et cookies :
              </h4>
              <div>
                <p className="cgu-article">
                  Le site{" "}
                  <a target="__blak" href="https://www.medik-dom.fr">
                    www.medik-dom.fr
                  </a>
                  contient un certain nombre de liens hypertextes vers d’autres
                  sites (partenaires, informations …) mis en place avec
                  l’autorisation de le propriétaire du site. Cependant, le
                  propriétaire du site n’a pas la possibilité de vérifier le
                  contenu des sites ainsi visités et décline donc toute
                  responsabilité de ce fait quand aux risques éventuels de
                  contenus illicites.
                </p>

                <p className="cgu-article">
                  L’utilisateur est informé que lors de ses visites sur le site{" "}
                  <a target="__blak" href="https://www.medik-dom.fr">
                    www.medik-dom.fr
                  </a>
                  , un ou des cookies sont susceptible de s’installer
                  automatiquement sur son ordinateur. Un cookie est un fichier
                  de petite taille, qui ne permet pas l’identification de
                  l’utilisateur, mais qui enregistre des informations relatives
                  à la navigation d’un ordinateur sur un site. Les données ainsi
                  obtenues visent à faciliter la navigation ultérieure sur le
                  site, et ont également vocation à permettre diverses mesures
                  de fréquentation.
                </p>

                <p className="cgu-article">
                  Le paramétrage du logiciel de navigation permet d’informer de
                  la présence de cookie et éventuellement, de refuser de la
                  manière décrite à l’adresse suivante :
                  <a target="__blank" href="www.cnil.fr">
                    www.cnil.fr
                  </a>
                </p>

                <p className="cgu-article">
                  Le refus d’installation d’un cookie peut entraîner
                  l’impossibilité d’accéder à certains services. L’utilisateur
                  peut toutefois configurer son ordinateur de la manière
                  suivante, pour refuser l’installation des cookies :
                </p>
                <p className="cgu-article cgu-article--sub">
                  Sous Internet Explorer :{" "}
                  <span className="font-bold">
                    onglet outil / options internet
                  </span>
                  . Cliquez sur Confidentialité et choisissez Bloquer tous les
                  cookies. Validez sur Ok.
                </p>

                <p className="cgu-article cgu-article--sub">
                  Sous Netscape :{" "}
                  <span className="font-bold">
                    onglet édition / préférences
                  </span>
                  . Cliquez sur Avancées et choisissez Désactiver les cookies.
                  Validez sur Ok.
                </p>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                5. Protection des biens et des personnes – gestion des données
                personnelles :
              </h4>
              <div>
                <p className="cgu-article">
                  <span className="font-bold">Utilisateur</span> : Internaute se
                  connectant, utilisant le site susnommé :
                  <a target="__blank" href="https://www.medik-dom.fr">
                    "www.medik-dom.fr
                  </a>{" "}
                  En France, les données personnelles sont notamment protégées
                  par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6
                  août 2004, l’article L. 226-13 du Code pénal et la Directive
                  Européenne du 24 octobre 1995.
                </p>

                <p className="cgu-article">
                  Sur le site{" "}
                  <a trget="__blank" href="https://www.medik-dom.fr">
                    www.medik-dom.fr
                  </a>
                  , le propriétaire du site ne collecte des informations
                  personnelles relatives à l’utilisateur que pour le besoin de
                  certains services proposés par le site www.medik-dom.fr.
                  L’utilisateur fournit ces informations en toute connaissance
                  de cause, notamment lorsqu’il procède par lui-même à leur
                  saisie. Il est alors précisé à l’utilisateur du site
                  www.medik-dom.fr l’obligation ou non de fournir ces
                  informations.
                </p>

                <p className="cgu-article">
                  Conformément aux dispositions des articles 38 et suivants de
                  la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux
                  fichiers et aux libertés, tout utilisateur dispose d’un droit
                  d’accès, de rectification, de suppression et d’opposition aux
                  données personnelles le concernant. Pour l’exercer, adressez
                  votre demande à www.medik-dom.fr par email : email du
                  webmaster ou en effectuant sa demande écrite et signée,
                  accompagnée d’une copie du titre d’identité avec signature du
                  titulaire de la pièce, en précisant l’adresse à laquelle la
                  réponse doit être envoyée.
                </p>

                <p className="cgu-article">
                  Aucune information personnelle de l’utilisateur du site{" "}
                  <a target="__blank" href="https://www.medik-dom.fr">
                    www.medik-dom.fr
                  </a>{" "}
                  n’est publiée à l’insu de l’utilisateur, échangée, transférée,
                  cédée ou vendue sur un support quelconque à des tiers. Seule
                  l’hypothèse du rachat du site www.medik-dom.fr à le
                  propriétaire du site et de ses droits permettrait la
                  transmission des dites informations à l’éventuel acquéreur qui
                  serait à son tour tenu de la même obligation de conservation
                  et de modification des données vis à vis de l’utilisateur du
                  site{" "}
                  <a target="__blank" href="https://www.medik-dom.fr">
                    www.medik-dom.fr
                  </a>
                  .
                </p>

                <p className="cgu-article">
                  Les bases de données sont protégées par les dispositions de la
                  loi du 1er juillet 1998 transposant la directive 96/9 du 11
                  mars 1996 relative à la protection juridique des bases de
                  données.
                </p>
              </div>
            </div>

            <div className="cgu-section__group">
              <h4 className="cgu-section__group__title">
                6. Formulaire de contact
              </h4>
              <div>
                <p className="cgu-article">
                  Les informations recueillies sur le formulaire de contact du
                  site internet{" "}
                  <a target="__blank" href="https://www.medik-dom.fr">
                    www.medik-dom.fr
                  </a>{" "}
                  sont envoyées par e-mail pour réaliser des propositions
                  commerciales relatives aux services de XXXXXXXXX SARL.
                </p>

                <p className="cgu-article">
                  Ces informations sont envoyées par le protocole https sous un
                  certificat SSL permettant de protéger les informations lors de
                  leur envoi. Elles sont conservées pendant 1 an et sont
                  destinées à XXXXXXXX SARL, gestionnaire du site.
                </p>

                <div className="cgu-article">
                  Ces données sont les suivantes :
                </div>

                <div className="cgu-article cgu-article--sub">
                  <ul>
                    <li className="font-bold">- Nom</li>
                    <li className="font-bold">- Adresse e-mail</li>
                    <li className="font-bold">- Téléphone</li>
                    <li className="font-bold">- Message</li>
                  </ul>
                </div>

                <div className="cgu-article">
                  dans lequel d’autres données personnelles pourraient être
                  renseignées par l’utilisateur. Les données fournies sont
                  uniquement stockées sur la boite e-mail de XXXXXXXXX SARL. Les
                  appareils où ces données sont accessibles (téléphones mobiles
                  et ordinateurs) sont protégés par un mot de passe. Dans tous
                  les cas, l’utilisateur peut refuser de fournir ses données
                  personnelles.
                </div>

                <p className="cgu-article">
                  Dans ce cas, il ne pourra pas utiliser les services du site,
                  ni solliciter de renseignements sur ces services.
                </p>

                <p className="cgu-article">
                  Conformément à la loi{" "}
                  <span className="font-bold">
                    « informatique et libertés »
                  </span>
                  , tout utilisateur dispose d’un droit d’accès, de
                  rectification et d’opposition aux données personnelles le
                  concernant en contactant : XXXXXXXXX SARL ou de la personne
                  qui supprimera ou modifiera les données clients, son mail,
                  l’adresse de l’entreprise.
                </p>

                <p className="cgu-article">
                  Aucune information personnelle de l’utilisateur du site{" "}
                  <a target="__blank" href="https://www.medik-dom.fr">
                    {" "}
                    www.medik-dom.fr
                  </a>{" "}
                  n’est publiée à l’insu de l’utilisateur, échangée, transférée,
                  cédée ou vendue sur un support quelconque à des tiers. Seule
                  l’hypothèse du rachat de XXXXXXXXX SARL et de ses droits
                  permettrait la transmission des dites informations à
                  l’éventuel acquéreur qui serait à son tour tenu de la même
                  obligation de conservation et de modification des données vis
                  à vis de l’utilisateur du site www.medik-dom.fr.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Cgu;
