import React, { Component } from "react";
import "../../assets/styles/styles.scss";
import Menu from "../../components/dashboard/Menu";
import Header from "../../components/dashboard/Header";

import Metadata from "../../components/Metadata";
import MedicalCard from "../../components/dashboard/MedicalCard";
import CardProgressBar from "../../components/dashboard/CardProgressBar";

import archive2_icon from "../../assets/images/archive2_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";
import filter_icon from "../../assets/images/filter_icon.svg";
import upload_icon from "../../assets/images/upload_icon.svg";
import new_folder_icon from "../../assets/images/new_folder_icon.svg";
import grid_icon from "../../assets/images/grid_icon.svg";
import list_icon from "../../assets/images/list_icon.svg";
import folder_2_icon from "../../assets/images/folder_2_icon.svg";
import arrow_down_icon from "../../assets/images/arrow_down_icon.svg";
import arrow_down_22_icon from "../../assets/images/arrow_down_22_icon.svg";
import file_icon from "../../assets/images/file_icon.svg";
import drag_n_drop_files_icon from "../../assets/images/drag_n_drop_files_icon.svg";
import temp_files_icon from "../../assets/images/temp_files_icon.svg";
import action_dots_icon from "../../assets/images/action_dots_icon.svg";

class Archive extends Component {
  
  render() {
    return (
      <div className="wrapper wrapper--light">
        <Metadata title="Dashboard - Archive" />
        <Header />

        <div className="grid grid--dashboard">
          <Menu />

          <div className="container container--dashboard">
            <h2 className="dashboard-title">Dashboard</h2>
            <div className="flex-double no-center">

                <div className="flex-double__l w-35"> 
                  {/*<div className="box-card">
                    <div className="box-card__header">
                        <div className="t-button">
                            On Course
                        </div>
                    </div>

                    <div className="__list">
                      {[0, 1, 2, 3, 4, 5].map((data, key) => (
                        <div className="__item flex-double">
                          <div className="flex-double__l w-auto">
                            <div className="flex-double">
                              <div className="__icon">
                              </div>
                              <div className="card-medical__text-tip __big __text">
                                Hospital A Adresse de livraison 1234
                              </div>
                            </div>
                          </div>
                          <div className="card-medical__text-tip __fade flex-double__r __r w-auto">
                            Arrival Time | 10:50pm
                          </div>
                        </div>
                      ))}

                    </div>

                    <div className="box-card__footer">
                      Delivered
                    </div>
                  </div>*/}
                  <div className="archive-hierarchy">
                    <div className="archive-hierarchy__item">
                      <div class="label-group">
                        <img
                          src={folder_2_icon}
                          alt="Folder icon"
                          title="Folder icon"
                        />
                        Root
                      </div>
                        <img
                          src={arrow_down_icon}
                          alt="Arrow down icon"
                          title="Arrow down icon"
                        />
                    </div>
                    <div className="archive-hierarchy__item __sub">
                      <div class="label-group">
                        <img
                          src={folder_2_icon}
                          alt="Folder icon"
                          title="Folder icon"
                        />
                        Temporaires
                      </div>
                        <img
                          src={arrow_down_icon}
                          alt="Arrow down icon"
                          title="Arrow down icon"
                        />
                    </div>

                    <div className="archive-hierarchy__item __sub">
                      <div class="label-group">
                        <img
                          src={folder_2_icon}
                          alt="Folder icon"
                          title="Folder icon"
                        />
                        Tiket N° 123123
                      </div>
                        <img
                          src={arrow_down_icon}
                          alt="Arrow down icon"
                          title="Arrow down icon"
                        />
                    </div>

                    <div className="archive-hierarchy__item __sub">
                      <div class="label-group">
                        <img
                          src={folder_2_icon}
                          alt="Folder icon"
                          title="Folder icon"
                        />
                        Tiket N° 123123
                      </div>
                        <img
                          src={arrow_down_icon}
                          alt="Arrow down icon"
                          title="Arrow down icon"
                        />
                    </div>

                    <div className="archive-hierarchy__item __sub">
                      <div class="label-group">
                        <img
                          src={folder_2_icon}
                          alt="Folder icon"
                          title="Folder icon"
                        />
                        Tiket N° 123123
                      </div>
                        <img
                          src={arrow_down_icon}
                          alt="Arrow down icon"
                          title="Arrow down icon"
                        />
                    </div>

                    <div className="archive-hierarchy__item __sub">
                      <div class="label-group">
                        <img
                          src={folder_2_icon}
                          alt="Folder icon"
                          title="Folder icon"
                        />
                        Tiket N° 123123
                      </div>
                        <img
                          src={arrow_down_icon}
                          alt="Arrow down icon"
                          title="Arrow down icon"
                        />
                    </div>
                  </div>
                </div>

                <div className="flex-double__r w-65">
                    <div class="archive-toolbar flex-double">
                      <div className="searchbar-box">
                        <input type="text" />
                          <img
                            src={search_icon}
                            alt="Search Bar Icon"
                            title="Search Bar Icon"
                            className="search-icon"
                            />
                      </div>
                      <div className="tools-one">
                        <div className="tools-grp">
                          <img
                              src={filter_icon}
                              alt="Filter Icon"
                              title="Filter Icon"
                              className="filter-icon"
                              />
                          <div className="__label">Upload file</div>

                          <img
                              src={upload_icon}
                              alt="Upload Icon"
                              title="Upload Icon"
                              />

                        </div>
                        <div className="tools-grp">
                          <div className="__label">New folder</div>
                          <img
                              src={new_folder_icon}
                              alt="Upload Icon"
                              title="Upload Icon"
                              />
                        </div>
                      </div>
                      
                      <div className="tools-two">
                        <div>
                          <img
                              src={grid_icon}
                              alt="Grid icon"
                              title="Grid icon"
                              />
                        </div>

                        <div>
                          <img
                              src={list_icon}
                              alt="List icon"
                              title="List icon"
                              />
                        </div>
                      </div>

                    </div>

                    <div className="archive-boxes">
                      {[0, 1, 2, 3, 4, 5].map((data, key) => (
                          <div className="archive-box">
                              <div className="archive-box__icon">
                                <img
                                  src={archive2_icon}
                                  alt="Archive"
                                  title="Archive"
                                />
                              </div>
                              <div className="archive-box__label">Ticket 1234-{key}</div>
                          </div>
                      ))}

                      {[0, 1, 2, 3, 4, 5].map((data, key) => (
                          <div className="archive-box">
                              <div className="archive-box__icon">
                                <img
                                  src={file_icon}
                                  alt="File icon"
                                  title="File icon"
                                />
                              </div>
                              <div className="archive-box__label">Temp-{key}.jpg</div>
                          </div>
                      ))}
                    </div>

                    <div className="archive-boxes no-files">
                      <img
                        src={drag_n_drop_files_icon}
                        alt="Drag and drop icon"
                        title="Drag and drop icon"
                        />
                    </div>
                    
                    <div className="archive-box-list">
                      <div className="archive-box-list__item __header">
                          <div className="name">
                            Name
                            <img
                              src={arrow_down_22_icon}
                              alt="Arrow down icon"
                              title="Arrow down icon"
                              style={{ transform: "rotate(90deg)", marginLeft: "10px"}}
                            />
                          </div>
                          <div className="date-created">
                            Date Created
                          </div>
                          <div className="date-modified">
                            Date Modified
                          </div>
                          <div className="size">
                            Size
                          </div>
                          <div className="action">
                          </div>
                        </div>
                      {[0, 1, 2, 3, 4, 5].map((data, key) => (
                        <div className="archive-box-list__item">
                          <div className="name">
                          <img
                              src={temp_files_icon}
                              alt="Temp files icon"
                              title="Temp files icon"
                            />
                            temp.jpg
                          </div>
                          <div className="date-created">
                            12 / 04 / 2020
                          </div>
                          <div className="date-modified">
                            12 / 04 / 2020
                          </div>
                          <div className="size">
                            100 KB
                          </div>
                          <div className="action">
                            <img
                                src={action_dots_icon}
                                alt="Action dots"
                                title="Action dots"
                            />
                          </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default Archive;
